// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---web-template-src-pages-404-tsx": () => import("./../../../../web-template/src/pages/404.tsx" /* webpackChunkName: "component---web-template-src-pages-404-tsx" */),
  "component---web-template-src-pages-about-tsx": () => import("./../../../../web-template/src/pages/about.tsx" /* webpackChunkName: "component---web-template-src-pages-about-tsx" */),
  "component---web-template-src-pages-archive-tsx": () => import("./../../../../web-template/src/pages/archive.tsx" /* webpackChunkName: "component---web-template-src-pages-archive-tsx" */),
  "component---web-template-src-pages-contact-tsx": () => import("./../../../../web-template/src/pages/contact.tsx" /* webpackChunkName: "component---web-template-src-pages-contact-tsx" */),
  "component---web-template-src-pages-uses-tsx": () => import("./../../../../web-template/src/pages/uses.tsx" /* webpackChunkName: "component---web-template-src-pages-uses-tsx" */),
  "component---web-template-src-pages-work-with-me-tsx": () => import("./../../../../web-template/src/pages/work-with-me.tsx" /* webpackChunkName: "component---web-template-src-pages-work-with-me-tsx" */),
  "component---web-template-src-templates-article-template-tsx": () => import("./../../../../web-template/src/templates/article.template.tsx" /* webpackChunkName: "component---web-template-src-templates-article-template-tsx" */),
  "component---web-template-src-templates-articles-template-tsx": () => import("./../../../../web-template/src/templates/articles.template.tsx" /* webpackChunkName: "component---web-template-src-templates-articles-template-tsx" */),
  "component---web-template-src-templates-author-template-tsx": () => import("./../../../../web-template/src/templates/author.template.tsx" /* webpackChunkName: "component---web-template-src-templates-author-template-tsx" */),
  "component---web-template-src-templates-category-template-tsx": () => import("./../../../../web-template/src/templates/category.template.tsx" /* webpackChunkName: "component---web-template-src-templates-category-template-tsx" */),
  "component---web-template-src-templates-main-template-tsx": () => import("./../../../../web-template/src/templates/main.template.tsx" /* webpackChunkName: "component---web-template-src-templates-main-template-tsx" */),
  "component---web-template-src-templates-workshop-templates-tsx": () => import("./../../../../web-template/src/templates/workshop.templates.tsx" /* webpackChunkName: "component---web-template-src-templates-workshop-templates-tsx" */),
  "component---web-template-src-templates-workshops-templates-tsx": () => import("./../../../../web-template/src/templates/workshops.templates.tsx" /* webpackChunkName: "component---web-template-src-templates-workshops-templates-tsx" */)
}

