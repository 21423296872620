import styled from '@emotion/styled'

const ArticlesGradient = styled.div`
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 590px;
	z-index: 0;
	pointer-events: none;
	background: ${(p) => p.theme.colors.gradient};
	transition: ${(p) => p.theme.colorModeTransition};
`

export default ArticlesGradient
