import * as React from 'react'
import { useTheme } from '@theme'
import { Icon } from '@types'

const Workshop: Icon = () => {
	const { theme } = useTheme()
	return (
		<svg className="prefix__animated" viewBox="0 0 500 500">
			<style>
				{
					'@keyframes slideRight{0%{opacity:0;transform:translateX(30px)}}@keyframes slideDown{0%{opacity:0;transform:translateY(-30px)}}@keyframes zoomIn{0%{opacity:0;transform:scale(.5)}}@keyframes fadeIn{0%{opacity:0}}@keyframes slideLeft{0%{opacity:0;transform:translateX(-30px)}}svg:not(.prefix__animated) .prefix__animable{opacity:0}'
				}
			</style>
			<g
				id="prefix__background-simple--inject-36"
				className="prefix__animable"
				style={{
					transformOrigin: '246.504px 214.32px',
					animation: '1s 1 forwards cubic-bezier(.36,-.01,.5,1.38) slideRight',
					animationDelay: '0s',
				}}
			>
				<path
					id="prefix__eli9tewjy1g8p"
					fill={theme.colors.accent}
					d="M419.43 121.45s-25.16-50.36-80.71-57.82-94.33 28.91-135.21 47.56c-21.4 9.77-45.85 6-68.58 10.71C62.27 137 34.89 231.72 65.16 285.59c26.2 46.63 72.32 70.87 142.55 76.47s119.48 12.12 182.37-33.57 58.7-140.82 29.35-207.04z"
					className="prefix__animable"
					style={{
						transformOrigin: '246.504px 214.32px',
					}}
				/>
				<path
					id="prefix__el3almith7vd7"
					fill="#fff"
					d="M419.43 121.45s-25.16-50.36-80.71-57.82-94.33 28.91-135.21 47.56c-21.4 9.77-45.85 6-68.58 10.71C62.27 137 34.89 231.72 65.16 285.59c26.2 46.63 72.32 70.87 142.55 76.47s119.48 12.12 182.37-33.57 58.7-140.82 29.35-207.04z"
					className="prefix__animable"
					opacity={0.7}
					style={{
						transformOrigin: '246.504px 214.32px',
					}}
				/>
			</g>
			<g
				id="prefix__Board--inject-36"
				className="prefix__animable"
				style={{
					transformOrigin: '297.94px 195.1px',
					animation: '1s 1 forwards cubic-bezier(.36,-.01,.5,1.38) slideRight',
					animationDelay: '0s',
				}}
			>
				<path
					id="prefix__eld4ygal2bkzc"
					fill="#fff"
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M166.01 140.1h263.85v113.19H166.01z"
					className="prefix__animable"
					style={{
						transformOrigin: '297.935px 196.695px',
					}}
				/>
				<path
					id="prefix__el6o0c3vkvbe3"
					fill="#fff"
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M158.84 136.91h278.2v4.78h-278.2z"
					className="prefix__animable"
					style={{
						transformOrigin: '297.94px 139.3px',
					}}
				/>
				<path
					id="prefix__el28bgi2dwzvd"
					fill="#263238"
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M166.01 249.3h263.85v3.99H166.01z"
					className="prefix__animable"
					style={{
						transformOrigin: '297.935px 251.295px',
					}}
				/>
				<circle
					id="prefix__elrigr62rsjy8"
					cx={205.85}
					cy={178.54}
					r={18.11}
					fill="none"
					stroke="#263238"
					strokeMiterlimit={10}
					className="prefix__animable"
					style={{
						transformOrigin: '205.85px 178.54px',
					}}
					transform="rotate(-80.3)"
				/>
				<path
					id="prefix__elw9m801chjsl"
					fill="none"
					stroke="#263238"
					strokeMiterlimit={10}
					d="M205.52 155.16v51.37"
					className="prefix__animable"
					style={{
						transformOrigin: '205.52px 180.845px',
					}}
				/>
				<path
					id="prefix__eldcecpfe9p07"
					fill="none"
					stroke="#263238"
					strokeMiterlimit={10}
					d="M181.81 178.87h46.76"
					className="prefix__animable"
					style={{
						transformOrigin: '205.19px 178.87px',
					}}
				/>
				<path
					id="prefix__el3kitzr88l7t"
					fill="none"
					stroke="#263238"
					strokeMiterlimit={10}
					d="M279.53 183.27h46.76"
					className="prefix__animable"
					style={{
						transformOrigin: '302.91px 183.27px',
					}}
				/>
				<path
					id="prefix__el65vqexzcuak"
					fill="none"
					stroke="#263238"
					strokeMiterlimit={10}
					d="M302.58 158.91v51.37"
					className="prefix__animable"
					style={{
						transformOrigin: '302.58px 184.595px',
					}}
				/>
				<path
					id="prefix__elu09ju2xcrc"
					fill="none"
					stroke="#263238"
					strokeMiterlimit={10}
					d="M285.46 165.49l7.1 5.52a17.82 17.82 0 0021.56.25l6.91-5.11"
					className="prefix__animable"
					style={{
						transformOrigin: '303.245px 170.128px',
					}}
				/>
				<path
					id="prefix__elsruu6hkvxc"
					fill="none"
					stroke="#263238"
					strokeMiterlimit={10}
					d="M321 198.63l-7.1-5.52a17.85 17.85 0 00-21.57-.25l-6.9 5.11"
					className="prefix__animable"
					style={{
						transformOrigin: '303.215px 193.993px',
					}}
				/>
				<path
					id="prefix__el9cquwhz1xjr"
					fill="none"
					stroke="#263238"
					strokeMiterlimit={10}
					d="M386.47 158.41v43.13"
					className="prefix__animable"
					style={{
						transformOrigin: '386.47px 179.975px',
					}}
				/>
				<path
					id="prefix__elz0qdji4m1jm"
					fill="none"
					stroke="#263238"
					strokeMiterlimit={10}
					d="M363.3 180.29h43.78"
					className="prefix__animable"
					style={{
						transformOrigin: '385.19px 180.29px',
					}}
				/>
				<path
					id="prefix__elo52qd5qkt9"
					fill="none"
					stroke="#263238"
					strokeMiterlimit={10}
					d="M366.11 178.57v3.5"
					className="prefix__animable"
					style={{
						transformOrigin: '366.11px 180.32px',
					}}
				/>
				<path
					id="prefix__elydio1o1vqa"
					fill="none"
					stroke="#263238"
					strokeMiterlimit={10}
					d="M368.65 178.57v3.5"
					className="prefix__animable"
					style={{
						transformOrigin: '368.65px 180.32px',
					}}
				/>
				<path
					id="prefix__elak0gahnt2"
					fill="none"
					stroke="#263238"
					strokeMiterlimit={10}
					d="M371.2 178.57v3.5"
					className="prefix__animable"
					style={{
						transformOrigin: '371.2px 180.32px',
					}}
				/>
				<path
					id="prefix__el4gmjx1nowzg"
					fill="none"
					stroke="#263238"
					strokeMiterlimit={10}
					d="M373.74 178.57v3.5"
					className="prefix__animable"
					style={{
						transformOrigin: '373.74px 180.32px',
					}}
				/>
				<path
					id="prefix__elmkpnr7vf67"
					fill="none"
					stroke="#263238"
					strokeMiterlimit={10}
					d="M376.29 178.57v3.5"
					className="prefix__animable"
					style={{
						transformOrigin: '376.29px 180.32px',
					}}
				/>
				<path
					id="prefix__el9ezfatw1r9t"
					fill="none"
					stroke="#263238"
					strokeMiterlimit={10}
					d="M378.83 178.57v3.5"
					className="prefix__animable"
					style={{
						transformOrigin: '378.83px 180.32px',
					}}
				/>
				<path
					id="prefix__ellw9cbe3vwea"
					fill="none"
					stroke="#263238"
					strokeMiterlimit={10}
					d="M381.37 178.57v3.5"
					className="prefix__animable"
					style={{
						transformOrigin: '381.37px 180.32px',
					}}
				/>
				<path
					id="prefix__eln0nfs3jay4q"
					fill="none"
					stroke="#263238"
					strokeMiterlimit={10}
					d="M383.92 178.57v3.5"
					className="prefix__animable"
					style={{
						transformOrigin: '383.92px 180.32px',
					}}
				/>
				<path
					id="prefix__elr5q94flpqia"
					fill="none"
					stroke="#263238"
					strokeMiterlimit={10}
					d="M389 178.57v3.5"
					className="prefix__animable"
					style={{
						transformOrigin: '389px 180.32px',
					}}
				/>
				<path
					id="prefix__elfse91zd3qd"
					fill="none"
					stroke="#263238"
					strokeMiterlimit={10}
					d="M391.55 178.57v3.5"
					className="prefix__animable"
					style={{
						transformOrigin: '391.55px 180.32px',
					}}
				/>
				<path
					id="prefix__elnfvsy3c359d"
					fill="none"
					stroke="#263238"
					strokeMiterlimit={10}
					d="M394.09 178.57v3.5"
					className="prefix__animable"
					style={{
						transformOrigin: '394.09px 180.32px',
					}}
				/>
				<path
					id="prefix__elqs8fbvlfypp"
					fill="none"
					stroke="#263238"
					strokeMiterlimit={10}
					d="M396.64 178.57v3.5"
					className="prefix__animable"
					style={{
						transformOrigin: '396.64px 180.32px',
					}}
				/>
				<path
					id="prefix__eli2ip79vdd6i"
					fill="none"
					stroke="#263238"
					strokeMiterlimit={10}
					d="M399.18 178.57v3.5"
					className="prefix__animable"
					style={{
						transformOrigin: '399.18px 180.32px',
					}}
				/>
				<path
					id="prefix__eln53up39iyt"
					fill="none"
					stroke="#263238"
					strokeMiterlimit={10}
					d="M401.72 178.57v3.5"
					className="prefix__animable"
					style={{
						transformOrigin: '401.72px 180.32px',
					}}
				/>
				<path
					id="prefix__elzys9zur1vbj"
					fill="none"
					stroke="#263238"
					strokeMiterlimit={10}
					d="M404.27 178.57v3.5"
					className="prefix__animable"
					style={{
						transformOrigin: '404.27px 180.32px',
					}}
				/>
				<path
					id="prefix__el8c1pgnxg7tc"
					fill="none"
					stroke="#263238"
					strokeMiterlimit={10}
					d="M384.72 199.4h3.5"
					className="prefix__animable"
					style={{
						transformOrigin: '386.47px 199.4px',
					}}
				/>
				<path
					id="prefix__elhqi9gx6bxzv"
					fill="none"
					stroke="#263238"
					strokeMiterlimit={10}
					d="M384.72 196.85h3.5"
					className="prefix__animable"
					style={{
						transformOrigin: '386.47px 196.85px',
					}}
				/>
				<path
					id="prefix__elhu1b4nkkg3n"
					fill="none"
					stroke="#263238"
					strokeMiterlimit={10}
					d="M384.72 194.31h3.5"
					className="prefix__animable"
					style={{
						transformOrigin: '386.47px 194.31px',
					}}
				/>
				<path
					id="prefix__elojzg2xs18y"
					fill="none"
					stroke="#263238"
					strokeMiterlimit={10}
					d="M384.72 191.76h3.5"
					className="prefix__animable"
					style={{
						transformOrigin: '386.47px 191.76px',
					}}
				/>
				<path
					id="prefix__elxa9kzjmwkxf"
					fill="none"
					stroke="#263238"
					strokeMiterlimit={10}
					d="M384.72 189.22h3.5"
					className="prefix__animable"
					style={{
						transformOrigin: '386.47px 189.22px',
					}}
				/>
				<path
					id="prefix__el49u4jfkgw7t"
					fill="none"
					stroke="#263238"
					strokeMiterlimit={10}
					d="M384.72 186.68h3.5"
					className="prefix__animable"
					style={{
						transformOrigin: '386.47px 186.68px',
					}}
				/>
				<path
					id="prefix__elfjvsiotkd4j"
					fill="none"
					stroke="#263238"
					strokeMiterlimit={10}
					d="M384.72 184.13h3.5"
					className="prefix__animable"
					style={{
						transformOrigin: '386.47px 184.13px',
					}}
				/>
				<path
					id="prefix__elj0hcpxoi9p"
					fill="none"
					stroke="#263238"
					strokeMiterlimit={10}
					d="M384.72 176.5h3.5"
					className="prefix__animable"
					style={{
						transformOrigin: '386.47px 176.5px',
					}}
				/>
				<path
					id="prefix__elqbckfjbg01"
					fill="none"
					stroke="#263238"
					strokeMiterlimit={10}
					d="M384.72 173.96h3.5"
					className="prefix__animable"
					style={{
						transformOrigin: '386.47px 173.96px',
					}}
				/>
				<path
					id="prefix__elbz8w8xv0yx5"
					fill="none"
					stroke="#263238"
					strokeMiterlimit={10}
					d="M384.72 171.41h3.5"
					className="prefix__animable"
					style={{
						transformOrigin: '386.47px 171.41px',
					}}
				/>
				<path
					id="prefix__elaxgm27ypi6f"
					fill="none"
					stroke="#263238"
					strokeMiterlimit={10}
					d="M384.72 168.87h3.5"
					className="prefix__animable"
					style={{
						transformOrigin: '386.47px 168.87px',
					}}
				/>
				<path
					id="prefix__elu8xlvxvnbmm"
					fill="none"
					stroke="#263238"
					strokeMiterlimit={10}
					d="M384.72 166.33h3.5"
					className="prefix__animable"
					style={{
						transformOrigin: '386.47px 166.33px',
					}}
				/>
				<path
					id="prefix__elk1xp38w45ns"
					fill="none"
					stroke="#263238"
					strokeMiterlimit={10}
					d="M384.72 163.78h3.5"
					className="prefix__animable"
					style={{
						transformOrigin: '386.47px 163.78px',
					}}
				/>
				<path
					id="prefix__el2xofxmy9644"
					fill="none"
					stroke="#263238"
					strokeMiterlimit={10}
					d="M384.72 161.24h3.5"
					className="prefix__animable"
					style={{
						transformOrigin: '386.47px 161.24px',
					}}
				/>
				<path
					id="prefix__elqgykzzloe2i"
					fill="none"
					stroke="#263238"
					strokeMiterlimit={10}
					d="M184.98 217h39.94"
					className="prefix__animable"
					style={{
						transformOrigin: '204.95px 217px',
					}}
				/>
				<path
					id="prefix__eldu3qumz0n9l"
					fill="none"
					stroke="#263238"
					strokeMiterlimit={10}
					d="M184.98 221.92h39.94"
					className="prefix__animable"
					style={{
						transformOrigin: '204.95px 221.92px',
					}}
				/>
				<path
					id="prefix__elalwaqvhzex"
					fill="none"
					stroke="#263238"
					strokeMiterlimit={10}
					d="M184.98 226.84h39.94"
					className="prefix__animable"
					style={{
						transformOrigin: '204.95px 226.84px',
					}}
				/>
				<path
					id="prefix__eljz3nv11bx"
					fill="none"
					stroke="#263238"
					strokeMiterlimit={10}
					d="M184.98 231.77h39.94"
					className="prefix__animable"
					style={{
						transformOrigin: '204.95px 231.77px',
					}}
				/>
				<path
					id="prefix__el6bayn1abdct"
					fill="none"
					stroke="#263238"
					strokeMiterlimit={10}
					d="M286.19 217h39.94"
					className="prefix__animable"
					style={{
						transformOrigin: '306.16px 217px',
					}}
				/>
				<path
					id="prefix__elmc1ur2kil7a"
					fill="none"
					stroke="#263238"
					strokeMiterlimit={10}
					d="M286.19 221.92h39.94"
					className="prefix__animable"
					style={{
						transformOrigin: '306.16px 221.92px',
					}}
				/>
				<path
					id="prefix__elag1h9i7lzod"
					fill="none"
					stroke="#263238"
					strokeMiterlimit={10}
					d="M286.19 226.84h39.94"
					className="prefix__animable"
					style={{
						transformOrigin: '306.16px 226.84px',
					}}
				/>
				<path
					id="prefix__elxzkcjrllrys"
					fill="none"
					stroke="#263238"
					strokeMiterlimit={10}
					d="M286.19 231.77h39.94"
					className="prefix__animable"
					style={{
						transformOrigin: '306.16px 231.77px',
					}}
				/>
				<path
					id="prefix__elaelsk6a0sh"
					fill="none"
					stroke="#263238"
					strokeMiterlimit={10}
					d="M368.8 217h39.94"
					className="prefix__animable"
					style={{
						transformOrigin: '388.77px 217px',
					}}
				/>
				<path
					id="prefix__el1bj30ithmm"
					fill="none"
					stroke="#263238"
					strokeMiterlimit={10}
					d="M368.8 221.92h39.94"
					className="prefix__animable"
					style={{
						transformOrigin: '388.77px 221.92px',
					}}
				/>
				<path
					id="prefix__el52qddtiijzj"
					fill="none"
					stroke="#263238"
					strokeMiterlimit={10}
					d="M368.8 226.84h39.94"
					className="prefix__animable"
					style={{
						transformOrigin: '388.77px 226.84px',
					}}
				/>
				<path
					id="prefix__elaax6v5o59g4"
					fill="none"
					stroke="#263238"
					strokeMiterlimit={10}
					d="M368.8 231.77h39.94"
					className="prefix__animable"
					style={{
						transformOrigin: '388.77px 231.77px',
					}}
				/>
				<path
					id="prefix__elmdb0bsnpm6f"
					fill="none"
					stroke="#263238"
					strokeMiterlimit={10}
					d="M234.77 190.74h39.93"
					className="prefix__animable"
					style={{
						transformOrigin: '254.735px 190.74px',
					}}
				/>
				<path
					id="prefix__elhx0dd1aiade"
					fill="none"
					stroke="#263238"
					strokeMiterlimit={10}
					d="M234.77 195.66h39.93"
					className="prefix__animable"
					style={{
						transformOrigin: '254.735px 195.66px',
					}}
				/>
				<path
					id="prefix__el6gy42fcuhu7"
					fill="none"
					stroke="#263238"
					strokeMiterlimit={10}
					d="M234.77 200.58h39.93"
					className="prefix__animable"
					style={{
						transformOrigin: '254.735px 200.58px',
					}}
				/>
				<path
					id="prefix__elz2cgxsx78k"
					fill="none"
					stroke="#263238"
					strokeMiterlimit={10}
					d="M234.77 205.51h39.93"
					className="prefix__animable"
					style={{
						transformOrigin: '254.735px 205.51px',
					}}
				/>
				<path
					id="prefix__elzumrzj6i96"
					fill="none"
					stroke="#263238"
					strokeMiterlimit={10}
					d="M324.49 154.08h39.93"
					className="prefix__animable"
					style={{
						transformOrigin: '344.455px 154.08px',
					}}
				/>
				<path
					id="prefix__el8bmlmdrqu7g"
					fill="none"
					stroke="#263238"
					strokeMiterlimit={10}
					d="M324.49 159.01h39.93"
					className="prefix__animable"
					style={{
						transformOrigin: '344.455px 159.01px',
					}}
				/>
				<path
					id="prefix__elstc4pmw2a2"
					fill="none"
					stroke="#263238"
					strokeMiterlimit={10}
					d="M324.49 163.93h39.93"
					className="prefix__animable"
					style={{
						transformOrigin: '344.455px 163.93px',
					}}
				/>
				<path
					id="prefix__elrmaaezdky9"
					fill="none"
					stroke="#263238"
					strokeMiterlimit={10}
					d="M324.49 168.85h39.93"
					className="prefix__animable"
					style={{
						transformOrigin: '344.455px 168.85px',
					}}
				/>
			</g>
			<g
				id="prefix__table-3--inject-36"
				className="prefix__animable"
				style={{
					transformOrigin: '106.97px 327.5px',
					animation: '1s 1 forwards cubic-bezier(.36,-.01,.5,1.38) slideDown',
					animationDelay: '0s',
				}}
			>
				<path
					id="prefix__el8at40mhlca2"
					fill="#263238"
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M43.05 253h51.51v43.27H43.05z"
					className="prefix__animable"
					style={{
						transformOrigin: '68.805px 274.635px',
					}}
				/>
				<path
					id="prefix__el4vw02pgr5ng"
					fill="#263238"
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M74.31 301.77h51.51v2.75H74.31z"
					className="prefix__animable"
					style={{
						transformOrigin: '100.065px 303.145px',
					}}
				/>
				<path
					id="prefix__elxy3ir92bpok"
					fill="#707070"
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M62.29 284.25h9.96v19.92h-9.96z"
					className="prefix__animable"
					style={{
						transformOrigin: '67.27px 294.21px',
					}}
				/>
				<path
					id="prefix__ellxpslv82yd"
					fill="#707070"
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M37.22 308.45h137.37v91.39H37.22z"
					className="prefix__animable"
					style={{
						transformOrigin: '105.905px 354.145px',
					}}
				/>
				<path
					id="prefix__el10mw1cbvnhhe"
					fill="#707070"
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M34.84 309.11h4.63V402h-4.63z"
					className="prefix__animable"
					style={{
						transformOrigin: '37.155px 355.555px',
					}}
				/>
				<path
					id="prefix__ellk4jafi9r1"
					fill="#707070"
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M171.83 309.11h4.63V402h-4.63z"
					className="prefix__animable"
					style={{
						transformOrigin: '174.145px 355.555px',
					}}
				/>
				<path
					id="prefix__elxcf7dqlbevl"
					fill="#707070"
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M30.97 303.57h152v8.73h-152z"
					className="prefix__animable"
					style={{
						transformOrigin: '106.97px 307.935px',
					}}
				/>
			</g>
			<g
				id="prefix__character-3--inject-36"
				className="prefix__animable"
				style={{
					transformOrigin: '268.086px 290.507px',
					animation: '1s 1 forwards cubic-bezier(.36,-.01,.5,1.38) slideRight',
					animationDelay: '0s',
				}}
			>
				<path
					id="prefix__el6kxfzvntxk5"
					fill="#fff"
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M251.64 219.06s-5.52 3.43-7.31 7.91-9.25 27.63-11.19 30.62-1.94 7.61.3 10.15 6 4.17 9.1 1.19 4-8.95 7-16 5.53-26.89 7-30.92a31.89 31.89 0 001.79-7.46z"
					className="prefix__animable"
					style={{
						transformOrigin: '245.025px 242.542px',
					}}
				/>
				<path
					id="prefix__elk9cp6ioidud"
					fill="#263238"
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M296.71 277.28s.89 11.87.44 17.91-4.47 44.14-4.47 44.14l-5.82 51.4h-8.95l-6.5-75.8-8.72 75.58h-9.18s-5.37-47.6-6.94-59a107.87 107.87 0 01-.67-21.51c0-.67-2-15.89-2-20.14a74.11 74.11 0 01.6-8.66l-.37-1.64s16.56 4.48 28 1.79 22.6-6.26 22.6-6.26z"
					className="prefix__animable"
					style={{
						transformOrigin: '270.589px 332.91px',
					}}
				/>
				<path
					id="prefix__elsddt2tljpqp"
					fill="none"
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M286.86 390.73l2.68 9.18a1.27 1.27 0 01-.44 1.79c-1.12.67-13.66 0-13.66 0a1.75 1.75 0 01-.89-2c.22-1.57 3.36-9 3.36-9z"
					className="prefix__animable"
					style={{
						transformOrigin: '282.117px 396.349px',
					}}
				/>
				<path
					id="prefix__elqccs0ikcoub"
					fill="none"
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M262.69 390.51l.81 8.37a2.2 2.2 0 01-.39 1.47h0a2.16 2.16 0 01-1.76.9H244c-1.76 0-5.16-.06-4.83-1.79v0c.22-1.12 14.32-8.92 14.32-8.92z"
					className="prefix__animable"
					style={{
						transformOrigin: '251.329px 395.88px',
					}}
				/>
				<path
					id="prefix__eltcpykdgxye"
					fill="none"
					stroke="#6b6b6b"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M271.41 314.93l-2.23-9.44-8.06-5.6"
					className="prefix__animable"
					style={{
						transformOrigin: '266.265px 307.41px',
					}}
				/>
				<path
					id="prefix__elheataktfjqu"
					fill="#fff"
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M248.51 230.55V248c0 1-5.37 24-5.82 26.26s0 4.33 1.79 6.86 8.51 5.08 23.13 6.27 25.52-4.18 28.5-7.61-.45-10-1.49-19.25-1.35-38.53-5.52-40.43-17.61-8.35-17.61-8.35-7.61 0-12.68 2.24a26 26 0 00-8.66 6.71"
					className="prefix__animable"
					style={{
						transformOrigin: '269.914px 249.654px',
					}}
				/>
				<path
					id="prefix__el6njig5lpe8x"
					fill="#fff"
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M270.59 208.76a4 4 0 014 2.39c1.05 2.69-2.24 18.95-2.24 18.95s-7.31-5.22-9.1-5.22-10 5.22-10 5.22 2.69-11.19 5.08-15.52 8.95-6.71 11.64-6z"
					className="prefix__animable"
					style={{
						transformOrigin: '264.023px 219.278px',
					}}
				/>
				<path
					id="prefix__elizntw66jrxg"
					fill="#fff"
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M271.19 207.27s.6 1.49-2.09 8.66-4 13.28-5.67 11.63-3.58-10.29-3.58-10.29 8.65-6.27 11.34-10z"
					className="prefix__animable"
					style={{
						transformOrigin: '265.549px 217.551px',
					}}
				/>
				<path
					id="prefix__elkw1nc14r55h"
					fill="#fff"
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M247.19 195.71a120.19 120.19 0 002.92 12.29c1.45 4.36 3.32 10.81 8.31 12.26s6.45-2.28 8.53-4 5.4-7.28 5.4-10-.21-8.73-1.25-10.81-2.91-4.16-8.31-5.2-10.4-.16-15.6 5.46z"
					className="prefix__animable"
					style={{
						transformOrigin: '259.77px 205.23px',
					}}
				/>
				<path
					id="prefix__el3abdjscvrr"
					fill="#263238"
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M270.69 201.32s-3.12 1.25-3.95-.62-2.29-4.58-2.29-4.58-6.45 2.71-10.39 2.88-8.32-2.28-8.94-5 1.66-8.73 6.86-12 16-4.79 19.33.41a22.79 22.79 0 013.54 13.31c0 3.53-.21 6-.21 6a4.8 4.8 0 00-3.95-.4z"
					className="prefix__animable"
					style={{
						transformOrigin: '259.948px 190.372px',
					}}
				/>
				<path
					id="prefix__elcz1duw1pudp"
					fill="#fff"
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M269.44 202.78s1.87-4.79 3.74-4.16 2.08 4.37 1 6.65-3.74 3.12-4.36 2.71"
					className="prefix__animable"
					style={{
						transformOrigin: '272.137px 203.321px',
					}}
				/>
				<path
					id="prefix__elvroeohhpptc"
					fill="none"
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M255.46 205.31s7.52-.53 9.11-.32 1.37 2 .95 4-2.86 3.18-5.72 2.65-3.5-3-4-3.39-.74-.85-1.37-.53-.53 2.54-1.27 3.49-2.76 1.7-5 .53-2.12-4.55-2.12-5.72 1.16-1.06 1.69-1 7.73.29 7.73.29z"
					className="prefix__animable"
					style={{
						transformOrigin: '255.932px 208.639px',
					}}
				/>
				<path
					id="prefix__elkb8o408s2h"
					fill="none"
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M265.63 205.42l4.66-4.77"
					className="prefix__animable"
					style={{
						transformOrigin: '267.96px 203.035px',
					}}
				/>
				<path
					id="prefix__elv2fedfkdxi"
					fill="none"
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M246.56 204.99l1.8-2.22"
					className="prefix__animable"
					style={{
						transformOrigin: '247.46px 203.88px',
					}}
				/>
				<path
					id="prefix__el5eijm0y559c"
					fill="none"
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M254.29 208.91s-.42 1.27-.64 2.33.64 3.18 1.91 3 1.59-.85 1.59-.85"
					className="prefix__animable"
					style={{
						transformOrigin: '255.383px 211.58px',
					}}
				/>
				<path
					id="prefix__elcfblwy0rjhb"
					fill="none"
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M263 203.83a6.37 6.37 0 00-4.35.21"
					className="prefix__animable"
					style={{
						transformOrigin: '260.825px 203.792px',
					}}
				/>
				<path
					id="prefix__el5rw8rmozmm2"
					fill="none"
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M253.23 204.25a2.78 2.78 0 00-3.39-.11"
					className="prefix__animable"
					style={{
						transformOrigin: '251.535px 203.933px',
					}}
				/>
				<path
					id="prefix__elym7mq92me3"
					fill="#fff"
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M284.77 218.31s6.42 1.94 9.25 4.33 7.4 21.16 8.74 30.11 3.43 16.69 1.64 17.74-29.78-2.61-29.78-2.61l-.89-4.32 18.24-3.8s-10.19-20-10.48-25.48a19.26 19.26 0 011.19-8.65"
					className="prefix__animable"
					style={{
						transformOrigin: '289.411px 244.495px',
					}}
				/>
				<path
					id="prefix__elbb7x5ni6bvk"
					fill={theme.colors.accent}
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M254.93 253.26l7.9 19.4-13.42 4.92-8.36-18.8 13.88-5.52z"
					className="prefix__animable"
					style={{
						transformOrigin: '251.94px 265.42px',
					}}
				/>
				<path
					id="prefix__elei1mu83ccj8"
					fill={theme.colors.accent}
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M241.05 258.78l-9.55-7.01 8.5 19.7 9.41 6.11-8.36-18.8z"
					className="prefix__animable"
					style={{
						transformOrigin: '240.455px 264.675px',
					}}
				/>
				<path
					id="prefix__elscf1z1ll2zg"
					fill="#fff"
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M273.73 263.56s-10.75-3-11.94-3.88-11.19 3.13-11.79 3.13-.45.75.15 1.05 7.16-1.5 7.16-1.5-6.26 3.29-6.26 4a1.63 1.63 0 00.59 1.2s.15 1.34.9 1.64 8.35-1.2 8.35-1.2-6 2.24-5.22 2.69a2.28 2.28 0 002.39 0c.89-.45 6.27-2.09 6.27-2.09s4 1.19 6.26.6a35.22 35.22 0 004-1.35z"
					className="prefix__animable"
					style={{
						transformOrigin: '262.104px 265.291px',
					}}
				/>
				<path
					id="prefix__el6be4acll0ox"
					fill="none"
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M251.64 267.59l7.46-2.09"
					className="prefix__animable"
					style={{
						transformOrigin: '255.37px 266.545px',
					}}
				/>
				<path
					id="prefix__elq199356ro1a"
					fill="#fff"
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M233.14 257.59s5.07 3.73 6 4.92a3.07 3.07 0 01.6 2.39l-3.29-1.34s4.18 3.73 4.33 4.32 1.64 3.14-.15 2.84-7.16-3.13-8.21-4.63-1.34-8.5-1.34-8.5.42-1.49 2.06 0z"
					className="prefix__animable"
					style={{
						transformOrigin: '236.292px 263.836px',
					}}
				/>
				<path
					id="prefix__eldoq3tkmzjcq"
					fill="none"
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M240.75 268.78l-5.97-3.58"
					className="prefix__animable"
					style={{
						transformOrigin: '237.765px 266.99px',
					}}
				/>
				<path
					id="prefix__el5eugervhz2o"
					fill="none"
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M241.35 270.12s-5.37-2.38-6.42-3.28"
					className="prefix__animable"
					style={{
						transformOrigin: '238.14px 268.48px',
					}}
				/>
				<path
					id="prefix__eloh0d6gyk5oa"
					fill="none"
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M236.42 263.56l-1.79-1.2"
					className="prefix__animable"
					style={{
						transformOrigin: '235.525px 262.96px',
					}}
				/>
			</g>
			<g
				id="prefix__table-2--inject-36"
				className="prefix__animable"
				style={{
					transformOrigin: '367.305px 357.235px',
					animation: '1s 1 forwards cubic-bezier(.36,-.01,.5,1.38) zoomIn',
					animationDelay: '0s',
				}}
			>
				<path
					id="prefix__elqgmn4ivs18"
					fill="#fff"
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M269.09 317.46h4.16V402h-4.16z"
					className="prefix__animable"
					style={{
						transformOrigin: '271.17px 359.73px',
					}}
				/>
				<path
					id="prefix__ele2krkiukwgd"
					fill="#fff"
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M458.99 317.46h4.16V402h-4.16z"
					className="prefix__animable"
					style={{
						transformOrigin: '461.07px 359.73px',
					}}
				/>
				<path
					id="prefix__ellv0sv5ix73"
					fill="#fff"
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M265.61 312.47H469v7.85H265.61z"
					className="prefix__animable"
					style={{
						transformOrigin: '367.305px 316.395px',
					}}
				/>
			</g>
			<g
				id="prefix__table-1--inject-36"
				className="prefix__animable"
				style={{
					transformOrigin: '154.085px 357.235px',
					animation: '1s 1 forwards cubic-bezier(.36,-.01,.5,1.38) fadeIn',
					animationDelay: '0s',
				}}
			>
				<path
					id="prefix__eltzmk5jf1za"
					fill="#fff"
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M55.87 317.46h4.16V402h-4.16z"
					className="prefix__animable"
					style={{
						transformOrigin: '57.95px 359.73px',
					}}
				/>
				<path
					id="prefix__ell8pak24j1q"
					fill="#fff"
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M245.76 317.46h4.16V402h-4.16z"
					className="prefix__animable"
					style={{
						transformOrigin: '247.84px 359.73px',
					}}
				/>
				<path
					id="prefix__el7gvmiqmjlro"
					fill="#fff"
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M52.39 312.47h203.39v7.85H52.39z"
					className="prefix__animable"
					style={{
						transformOrigin: '154.085px 316.395px',
					}}
				/>
			</g>
			<g
				id="prefix__character-5--inject-36"
				className="prefix__animable"
				style={{
					transformOrigin: '420.355px 316.301px',
					animation: '1s 1 forwards cubic-bezier(.36,-.01,.5,1.38) zoomIn',
					animationDelay: '0s',
				}}
			>
				<path
					id="prefix__elex2z84263to"
					fill="#707070"
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M401.8 332.28l9.56 61.25 5.12.32 1.21-60.07-15.89-1.5z"
					className="prefix__animable"
					style={{
						transformOrigin: '409.745px 363.065px',
					}}
				/>
				<path
					id="prefix__elo4863pi33e"
					fill="#fff"
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M411.36 393.53v6.82a1.09 1.09 0 001 1.08l3.75.21a1.08 1.08 0 001.13-1.21l-.76-6.58z"
					className="prefix__animable"
					style={{
						transformOrigin: '414.304px 397.586px',
					}}
				/>
				<path
					id="prefix__elhsaoou8l6m"
					fill="#707070"
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M439.15 332.43l-10.61 61.04-5.13.2-.17-60.08 15.91-1.16z"
					className="prefix__animable"
					style={{
						transformOrigin: '431.195px 363.05px',
					}}
				/>
				<path
					id="prefix__eldvj4q0cqdi5"
					fill="#fff"
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M428.54 393.47l-.12 6.81a1.08 1.08 0 01-1.05 1.06l-3.76.13a1.08 1.08 0 01-1.1-1.23l.9-6.57z"
					className="prefix__animable"
					style={{
						transformOrigin: '425.52px 397.47px',
					}}
				/>
				<path
					id="prefix__el474n3yxjkf"
					fill={theme.colors.accent}
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M398.78 273.09s-16 31-16.44 33.64 2.18 6.55 6.76 6.11 16.51-.65 16.51-.65l.22-4.15-13.75-1.74 8.29-6.55z"
					className="prefix__animable"
					style={{
						transformOrigin: '394.061px 292.982px',
					}}
				/>
				<path
					id="prefix__eltj0ze62htvd"
					fill="none"
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M392.08 306.3l-1.67 3.27"
					className="prefix__animable"
					style={{
						transformOrigin: '391.245px 307.935px',
					}}
				/>
				<path
					id="prefix__el28t4o9kdxwh"
					fill={theme.colors.accent}
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M441.63 273.76s16.3 30.35 16.74 33-2.18 6.55-6.76 6.11-15.29-.65-15.29-.65l-.22-4.22 7.44-1.74-5.21-6.63z"
					className="prefix__animable"
					style={{
						transformOrigin: '447.259px 293.332px',
					}}
				/>
				<path
					id="prefix__elx666sp2wfa8"
					fill="none"
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M443.54 306.3l6.76 3.27"
					className="prefix__animable"
					style={{
						transformOrigin: '446.92px 307.935px',
					}}
				/>
				<path
					id="prefix__elgrk05doc22v"
					fill={theme.colors.accent}
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M414 264.92l-10.5 5.56c-5.25 2.78-4.4 1.22-4.71 2.61s1.77 49.57 1.77 49.57 4 3.9 14.52 4.94c15.74 1.54 22.53-3.4 22.53-3.4s4-48.74 4-50.44-6.5-5-10.66-7-8.78-3.69-16.95-1.84z"
					className="prefix__animable"
					style={{
						transformOrigin: '420.184px 296.011px',
					}}
				/>
				<path
					id="prefix__elqscbu0ndaes"
					fill="#263238"
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M419.6 232s-6.81 3-9 7.89 1.36 16.33.54 19.32-4.62 2.72-4.62 2.72 1.63 9.26 10.88 10.35 13.34-.28 17.15-4.36 3-4.08 3-4.08-1.63 0-2.45-4.63.27-17.69-3.81-23.4S421 230.32 419.6 232z"
					className="prefix__animable"
					style={{
						transformOrigin: '422.071px 251.8px',
					}}
				/>
				<path
					id="prefix__ela8woy0ebyxf"
					fill="#707070"
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M400.55 322.66s-1.38 15.43-.92 17.44 5.25 5.25 15 5.56 21.92.77 24.86-2.78-1.86-18.68-1.86-18.68-25.96 2.16-37.08-1.54z"
					className="prefix__animable"
					style={{
						transformOrigin: '419.99px 334.264px',
					}}
				/>
				<path
					id="prefix__el0bfvwybo9fij"
					fill="none"
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M420.09 264.63s.23-1.6 5-.23"
					className="prefix__animable"
					style={{
						transformOrigin: '422.59px 264.238px',
					}}
				/>
				<path
					id="prefix__elfh5780amt7"
					fill="#263238"
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M400.33 401.07h0a1.08 1.08 0 01-1-1.17l3.17-58.37 3.64.29-4.66 58.26a1.08 1.08 0 01-1.15.99z"
					className="prefix__animable"
					style={{
						transformOrigin: '402.733px 371.301px',
					}}
				/>
				<path
					id="prefix__el8ffxf30zj1k"
					fill="#263238"
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M439.58 401.07h0a1.09 1.09 0 001-1.17l-3.17-58.37-3.64.29 4.65 58.26a1.09 1.09 0 001.16.99z"
					className="prefix__animable"
					style={{
						transformOrigin: '437.177px 371.301px',
					}}
				/>
				<path
					id="prefix__elte1cgtirmmk"
					fill="#263238"
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M443.31 295.58a176.59 176.59 0 01-24.59 1.81 171.08 171.08 0 01-23-1.81 1.86 1.86 0 00-2.13 2.12c1.18 7.38 4.53 28.61 4.53 32.67 0 4.94-1.7 9.58-1.7 11.74s.93 3.86 2.47 4.47c.72.29 8.81.31 19.75.25 12.66.06 20.76 0 21.47-.25 1.54-.61 2.47-2.31 2.47-4.47s-1.7-6.8-1.7-11.74c0-4.06 3.35-25.3 4.54-32.67a1.85 1.85 0 00-2.11-2.12zm-7.92 45.23c-.55.21-6.79.23-16.55.18-8.43.05-14.67 0-15.22-.18a3.45 3.45 0 01-1.9-3.42c0-1.65 1.3-5.19 1.3-9 0-.88-.21-2.84-.53-5.32l3.09 3.35a4.53 4.53 0 003.31 1.44h21.2a4.51 4.51 0 003.15-1.28l3.23-3.15c-.3 2.31-.49 4.12-.49 5 0 3.77 1.31 7.31 1.31 9a3.45 3.45 0 01-1.9 3.38zm4.09-37.31c-.52 3.2-1.58 9.81-2.4 15.49L434 320.9a11 11 0 01-5.73 1.6H411a11 11 0 01-5.58-1.52l-3.51-2.06c-.81-5.67-1.86-12.24-2.38-15.42a1.43 1.43 0 011.64-1.62 132.59 132.59 0 0017.73 1.38 136.71 136.71 0 0019-1.38 1.42 1.42 0 011.58 1.62z"
					className="prefix__animable"
					style={{
						transformOrigin: '419.506px 321.207px',
					}}
				/>
			</g>
			<g
				id="prefix__character-4--inject-36"
				className="prefix__animable"
				style={{
					transformOrigin: '328.334px 320.281px',
					animation: '1s 1 forwards cubic-bezier(.36,-.01,.5,1.38) slideLeft',
					animationDelay: '0s',
				}}
			>
				<path
					id="prefix__elimmzdm9axb"
					fill="#fff"
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M322.28 277.52v-8.33h11.89v8.95l-11.89-.62z"
					className="prefix__animable"
					style={{
						transformOrigin: '328.225px 273.665px',
					}}
				/>
				<path
					id="prefix__eltrev7xky17"
					fill="#fff"
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M316.42 258.54s-1.86-.47-1.86 2.47S317 266 318 265.79s-.46-5.71-.62-6.79-.96-.46-.96-.46z"
					className="prefix__animable"
					style={{
						transformOrigin: '316.454px 262.084px',
					}}
				/>
				<path
					id="prefix__elxdpv4qxft"
					fill="#fff"
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M339.58 258.54s1.85-.47 1.85 2.47S339 266 338 265.79s.46-5.71.61-6.79.97-.46.97-.46z"
					className="prefix__animable"
					style={{
						transformOrigin: '339.541px 262.084px',
					}}
				/>
				<path
					id="prefix__eldqm79pqz7b8"
					fill="#263238"
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M339.62 246.91s1.16-.58-.14-3.34a5.67 5.67 0 00-3.77-3.19s1-.58 1-1.16-1.3-.72-5.22.44a5.1 5.1 0 00-4 4.52 1.59 1.59 0 00-.23-.11c-.95-.46-4.63-2.1-6.39-1.22-2 1 .29.72.29.72a9.15 9.15 0 00-5.8 3.48c-2.17 3-.58 3.34-.14 3.34s.87-1.31.87-1.31v2.76s.05.24.14.62c-.61 4.09.47 8.72 1.07 11.63.77 3.71 3.86 7.26 6.18 8s9.41 0 9.41 0a26.68 26.68 0 004.48-6 20.82 20.82 0 002-8.7 14.3 14.3 0 001.37-5.27c.33-3.76-1.12-5.21-1.12-5.21z"
					className="prefix__animable"
					style={{
						transformOrigin: '327.517px 255.601px',
					}}
				/>
				<path
					id="prefix__elsv9lz6jogn8"
					fill={theme.colors.accent}
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M335.87 262.61s-6.55-3.53-13-.77c-3.73 1.59-6.48 5.55-6.48 5.55a36.94 36.94 0 0110-1.54 122.64 122.64 0 0112.5.92 14 14 0 00-3.02-4.16z"
					className="prefix__animable"
					style={{
						transformOrigin: '327.64px 264.088px',
					}}
				/>
				<path
					id="prefix__elh09kxkhqgy6"
					fill={theme.colors.accent}
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M302.06 277.27s-17 26.84-17.44 29.46 2.18 6.55 6.76 6.11 21.6-.65 21.6-.65l.22-4.15-13.74-1.74 8.29-6.55z"
					className="prefix__animable"
					style={{
						transformOrigin: '298.886px 295.072px',
					}}
				/>
				<path
					id="prefix__el540pi0zab9m"
					fill="none"
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M299.46 306.3l-6.77 3.27"
					className="prefix__animable"
					style={{
						transformOrigin: '296.075px 307.935px',
					}}
				/>
				<path
					id="prefix__elorlw9v44h6a"
					fill={theme.colors.accent}
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M354.39 276.19s17.23 27.92 17.66 30.54-2.18 6.55-6.76 6.11-21.6-.65-21.6-.65l-.22-4.15 13.75-1.74-8.29-6.55z"
					className="prefix__animable"
					style={{
						transformOrigin: '357.783px 294.532px',
					}}
				/>
				<path
					id="prefix__elqebi59womwt"
					fill="none"
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M357.22 306.3l6.76 3.27"
					className="prefix__animable"
					style={{
						transformOrigin: '360.6px 307.935px',
					}}
				/>
				<path
					id="prefix__eluf2g22gp69"
					fill={theme.colors.accent}
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M321.36 264.92l-10.5 5.56c-5.25 2.78-8.49 5.4-8.8 6.79s5.87 45.39 5.87 45.39 4 3.9 14.51 4.94c15.74 1.54 22.53-3.4 22.53-3.4s9.42-46.31 9.42-48-11.89-7.41-16.05-9.42-8.8-3.71-16.98-1.86z"
					className="prefix__animable"
					style={{
						transformOrigin: '328.219px 296.012px',
					}}
				/>
				<path
					id="prefix__el2mudiviqhle"
					fill="#fff"
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M298.97 335.47l18.37 58.71 5.1-.62-7.57-59.48-15.9 1.39z"
					className="prefix__animable"
					style={{
						transformOrigin: '310.705px 364.13px',
					}}
				/>
				<path
					id="prefix__elkkvx8suu16g"
					fill="#fff"
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M317.34 394.18l1.21 6.71a1.08 1.08 0 001.2.88l3.73-.48a1.08 1.08 0 00.9-1.39l-1.94-6.34z"
					className="prefix__animable"
					style={{
						transformOrigin: '320.884px 397.669px',
					}}
				/>
				<path
					id="prefix__elh1dhkypk5so"
					fill="#fff"
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M355.61 335.47l-18.37 58.71-5.1-.62 7.57-59.48 15.9 1.39z"
					className="prefix__animable"
					style={{
						transformOrigin: '343.875px 364.13px',
					}}
				/>
				<path
					id="prefix__eloiamxhtmfo"
					fill="#fff"
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M337.24 394.18l-1.24 6.71a1.08 1.08 0 01-1.2.88l-3.73-.48a1.08 1.08 0 01-.9-1.39l1.94-6.34z"
					className="prefix__animable"
					style={{
						transformOrigin: '333.681px 397.669px',
					}}
				/>
				<path
					id="prefix__elj0b2qg3spkb"
					fill="#fff"
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M323.67 328.52s-16.82-5.25-19.91-3.7-6.18 5.55-5.1 10 10.35 9.88 12.35 10 12.82 0 12.82 0z"
					className="prefix__animable"
					style={{
						transformOrigin: '311.135px 334.704px',
					}}
				/>
				<path
					id="prefix__eloz2xbyfgt4f"
					fill="#fff"
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M331.94 328.52s16.83-5.25 19.92-3.7 6.17 5.55 5.09 10-10.34 9.88-12.35 10-12.81 0-12.81 0z"
					className="prefix__animable"
					style={{
						transformOrigin: '344.48px 334.704px',
					}}
				/>
				<path
					id="prefix__el7wlr84cvna"
					fill="#fff"
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M307.93 322.66s-1.39 15.43-.93 17.44 5.25 5.25 15 5.56 21.92.77 24.86-2.78S345 324.2 345 324.2s-26 2.16-37.07-1.54z"
					className="prefix__animable"
					style={{
						transformOrigin: '327.36px 334.264px',
					}}
				/>
				<path
					id="prefix__elwetf222wxq"
					fill="#263238"
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M307.7 401.07h0a1.09 1.09 0 01-1-1.17l3.17-58.37 3.64.29-4.65 58.26a1.09 1.09 0 01-1.16.99z"
					className="prefix__animable"
					style={{
						transformOrigin: '310.103px 371.301px',
					}}
				/>
				<path
					id="prefix__elmh2hw2mjx5"
					fill="#263238"
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M347 401.07h0a1.09 1.09 0 001-1.17l-3.17-58.37-3.64.29 4.65 58.26a1.09 1.09 0 001.16.99z"
					className="prefix__animable"
					style={{
						transformOrigin: '344.597px 371.301px',
					}}
				/>
				<path
					id="prefix__elskm73ieysi"
					fill="#263238"
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M350.64 295.58a176.59 176.59 0 01-24.59 1.81 171.34 171.34 0 01-23-1.81 1.86 1.86 0 00-2.13 2.12c1.19 7.38 4.54 28.61 4.54 32.67 0 4.94-1.7 9.58-1.7 11.74s.92 3.86 2.47 4.47c.71.29 8.8.31 19.74.25 12.67.06 20.76 0 21.47-.25 1.55-.61 2.47-2.31 2.47-4.47s-1.69-6.8-1.69-11.74c0-4.06 3.35-25.3 4.53-32.67a1.85 1.85 0 00-2.11-2.12zm-7.92 45.23c-.55.21-6.79.23-16.55.18-8.43.05-14.67 0-15.22-.18a3.45 3.45 0 01-1.9-3.42c0-1.65 1.31-5.19 1.31-9 0-.88-.21-2.84-.54-5.32l3.1 3.35a4.5 4.5 0 003.31 1.44h21.2a4.54 4.54 0 003.15-1.28l3.22-3.15c-.29 2.31-.48 4.12-.48 5 0 3.77 1.3 7.31 1.3 9a3.45 3.45 0 01-1.9 3.38zm4.09-37.31c-.52 3.2-1.58 9.81-2.39 15.49l-3.14 1.91a11 11 0 01-5.72 1.6h-17.22a11 11 0 01-5.59-1.52l-3.5-2.06c-.82-5.67-1.87-12.24-2.39-15.42a1.43 1.43 0 011.64-1.62 132.59 132.59 0 0017.73 1.38 136.59 136.59 0 0018.95-1.38 1.42 1.42 0 011.63 1.62z"
					className="prefix__animable"
					style={{
						transformOrigin: '326.836px 321.207px',
					}}
				/>
				<path
					id="prefix__el02nuraebcsgn"
					fill="#757575"
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M310.38 295.87l5.93 22.09 4.62-.66-4.95-20.44-5.6-.99z"
					className="prefix__animable"
					style={{
						transformOrigin: '315.655px 306.915px',
					}}
				/>
				<path
					id="prefix__eltgdgssn70g"
					fill="#757575"
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M333.79 316.64l4.29-19.78 5.94-.67-5.61 21.77-4.62-1.32z"
					className="prefix__animable"
					style={{
						transformOrigin: '338.905px 307.075px',
					}}
				/>
				<path
					id="prefix__el6cqiqy06wuu"
					fill="#757575"
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M304.82 331.87l7.87 16.43v7.26l-8.58-17.81.71-5.88z"
					className="prefix__animable"
					style={{
						transformOrigin: '308.4px 343.715px',
					}}
				/>
				<path
					id="prefix__el8xanee1k11g"
					fill="#757575"
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M348.97 331.87l-7.86 16.43v7.26l8.57-17.81-.71-5.88z"
					className="prefix__animable"
					style={{
						transformOrigin: '345.395px 343.715px',
					}}
				/>
				<path
					id="prefix__elva3l7lzi9od"
					fill={theme.colors.accent}
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M335.25 312.36s-14.18-1-18.47.33-6.59 4.61-6.26 9.89-2.64 25.72-2.31 31 2.31 8.58 10.88 10.23 17.48 1 22.43-1 7.31-9.54 4.28-17.48-2.27-20.15-3.59-26.08-4.65-6.56-6.96-6.89z"
					className="prefix__animable"
					style={{
						transformOrigin: '327.666px 338.373px',
					}}
				/>
				<path
					id="prefix__eldfjdy8bbybo"
					fill={theme.colors.accent}
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M343.25 352.12c0 .12-.23 3.27-.26 3.39-2.32 8.81-14.75 7.41-21.64 6.26-3.43-.57-8.57-2.3-9.9-6-1.45-4 .43-9 1.15-13.05.44-2.51.69-10.2 3.53-11.48s20.08-3.13 22.69 2.08c2.92 5.86 3.39 12.02 4.43 18.8z"
					className="prefix__animable"
					style={{
						transformOrigin: '327.083px 346.233px',
					}}
				/>
				<path
					id="prefix__el363sb4m62sn"
					fill="#263238"
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M323.69 318.47h6.52v3.65h-6.52z"
					className="prefix__animable"
					style={{
						transformOrigin: '326.95px 320.295px',
					}}
				/>
				<path
					id="prefix__elz6nt1z2a0y"
					fill="#263238"
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M313.52 343h28.17a4.26 4.26 0 01-3.39 4.44c-4.21.9-8.85.73-13.12.74-3.83-.01-12.1.56-11.66-5.18z"
					className="prefix__animable"
					style={{
						transformOrigin: '327.601px 345.596px',
					}}
				/>
			</g>
			<g
				id="prefix__character-2--inject-36"
				className="prefix__animable"
				style={{
					transformOrigin: '207.123px 317.163px',
					animation: '1s 1 forwards cubic-bezier(.36,-.01,.5,1.38) slideDown',
					animationDelay: '0s',
				}}
			>
				<path
					id="prefix__elwkykk36wdv"
					fill={theme.colors.accent}
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M188.57 332.64l9.56 61.26 5.12.31 1.21-60.06-15.89-1.51z"
					className="prefix__animable"
					style={{
						transformOrigin: '196.515px 363.425px',
					}}
				/>
				<path
					id="prefix__el1bvslwpuxh2"
					fill="#fff"
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M198.13 393.9v6.81a1.09 1.09 0 001 1.09l3.76.2a1.09 1.09 0 001.13-1.21l-.76-6.58z"
					className="prefix__animable"
					style={{
						transformOrigin: '201.078px 397.95px',
					}}
				/>
				<path
					id="prefix__elwuf0dm4a6wi"
					fill={theme.colors.accent}
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M225.93 332.79l-10.62 61.04-5.12.2-.18-60.07 15.92-1.17z"
					className="prefix__animable"
					style={{
						transformOrigin: '217.97px 363.41px',
					}}
				/>
				<path
					id="prefix__elv0o3wxvjcug"
					fill="#fff"
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M215.31 393.83l-.12 6.81a1.09 1.09 0 01-1 1.07l-3.76.12a1.09 1.09 0 01-1.11-1.23l.9-6.57z"
					className="prefix__animable"
					style={{
						transformOrigin: '212.31px 397.83px',
					}}
				/>
				<path
					id="prefix__elhjurtxn9jkl"
					fill="#fff"
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M185.56 273.45s-16 31-16.45 33.64 2.19 6.55 6.77 6.11 16.5-.65 16.5-.65l.22-4.15-13.74-1.74 8.29-6.55z"
					className="prefix__animable"
					style={{
						transformOrigin: '180.83px 293.342px',
					}}
				/>
				<path
					id="prefix__el1fgicxrq1zl"
					fill="none"
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M178.86 306.66l-1.67 3.27"
					className="prefix__animable"
					style={{
						transformOrigin: '178.025px 308.295px',
					}}
				/>
				<path
					id="prefix__el5i0kpjo28pl"
					fill="#fff"
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M228.59 272.78s16.12 31.7 16.55 34.31-2.18 6.55-6.76 6.11-15.28-.65-15.28-.65l-.22-4.15 7.43-1.74-4.69-6.51z"
					className="prefix__animable"
					style={{
						transformOrigin: '234.033px 293.007px',
					}}
				/>
				<path
					id="prefix__elfuauwdbr3y"
					fill="none"
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M230.31 306.66l6.76 3.27"
					className="prefix__animable"
					style={{
						transformOrigin: '233.69px 308.295px',
					}}
				/>
				<path
					id="prefix__elbmnkd2s0n5u"
					fill="#fff"
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M200.76 265.29l-10.5 5.55c-5.25 2.78-4.4 1.22-4.7 2.61s1.77 49.55 1.77 49.55 4 3.91 14.51 4.94c15.75 1.54 22.54-3.4 22.54-3.4s4-48.74 4-50.44-6.5-5-10.67-7-8.77-3.67-16.95-1.81z"
					className="prefix__animable"
					style={{
						transformOrigin: '206.955px 296.36px',
					}}
				/>
				<path
					id="prefix__elucrdplpw9c"
					fill={theme.colors.accent}
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M187.33 323s-1.39 15.44-.93 17.44 5.25 5.25 15 5.56 21.92.77 24.85-2.78-1.85-18.68-1.85-18.68-25.96 2.18-37.07-1.54z"
					className="prefix__animable"
					style={{
						transformOrigin: '206.754px 334.604px',
					}}
				/>
				<path
					id="prefix__el9pjnowg2cpr"
					fill="#263238"
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M208 235.06s-5-3-10.28 2.74.23 17.14.91 19.88S189.5 276 189.5 283s8.5 14 21.94 12.61 15.08-8.46 13.25-16.91-3.89-17.37-3.89-21 3.2-11.88.23-19.88-10.28-5.73-13.03-2.76z"
					className="prefix__animable"
					style={{
						transformOrigin: '207.403px 264.056px',
					}}
				/>
				<path
					id="prefix__elvxknnj0239"
					fill="none"
					stroke="gray"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M200.7 259.05l6.17 5.94s-6.4 15.31-7.09 19.42 3 8 8 7.54 8.23-4.8 8-8.45-3.89-18.74-3.89-18.74a20.62 20.62 0 007.77-7.08"
					className="prefix__animable"
					style={{
						transformOrigin: '209.678px 274.834px',
					}}
				/>
				<path
					id="prefix__elh9ep3otdl47"
					fill="none"
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M206.87 265s.23-1.6 5-.23"
					className="prefix__animable"
					style={{
						transformOrigin: '209.37px 264.608px',
					}}
				/>
				<path
					id="prefix__elvmr28nr5s89"
					fill="#263238"
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M187.1 401.43h0a1.09 1.09 0 01-1-1.16l3.17-58.38 3.64.3-4.65 58.25a1.08 1.08 0 01-1.16.99z"
					className="prefix__animable"
					style={{
						transformOrigin: '189.504px 371.662px',
					}}
				/>
				<path
					id="prefix__elyk39lyuo65"
					fill="#263238"
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M226.35 401.43h0a1.08 1.08 0 001-1.16l-3.16-58.38-3.64.3 4.65 58.25a1.08 1.08 0 001.15.99z"
					className="prefix__animable"
					style={{
						transformOrigin: '223.952px 371.661px',
					}}
				/>
				<path
					id="prefix__eln6oufg4bseq"
					fill="#263238"
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M230.08 295.94a176.56 176.56 0 01-24.58 1.81 169.73 169.73 0 01-23-1.81 1.86 1.86 0 00-2.12 2.13c1.18 7.37 4.53 28.6 4.53 32.67 0 4.94-1.7 9.57-1.7 11.73s.93 3.86 2.47 4.48c.71.28 8.8.3 19.74.24 12.67.06 20.76 0 21.48-.24 1.54-.62 2.47-2.32 2.47-4.48s-1.7-6.79-1.7-11.73c0-4.07 3.35-25.3 4.53-32.67a1.85 1.85 0 00-2.12-2.13zm-7.92 45.23c-.55.22-6.79.23-16.55.19-8.43 0-14.67 0-15.22-.19a3.45 3.45 0 01-1.9-3.42c0-1.65 1.31-5.18 1.31-8.95 0-.89-.21-2.85-.53-5.32l3.09 3.34a4.51 4.51 0 003.31 1.45h21.2A4.47 4.47 0 00220 327l3.22-3.14c-.29 2.3-.48 4.11-.48 5 0 3.77 1.31 7.3 1.31 8.95a3.45 3.45 0 01-1.89 3.36zm4.09-37.31a988.2 988.2 0 00-2.39 15.49l-3.13 1.91a11 11 0 01-5.73 1.61h-17.22a11 11 0 01-5.59-1.53l-3.5-2.06c-.82-5.66-1.87-12.23-2.38-15.42a1.42 1.42 0 011.64-1.62 132.27 132.27 0 0017.72 1.39 138.31 138.31 0 0019-1.39 1.42 1.42 0 011.58 1.62z"
					className="prefix__animable"
					style={{
						transformOrigin: '206.291px 321.567px',
					}}
				/>
			</g>
			<g
				id="prefix__character-1--inject-36"
				className="prefix__animable"
				style={{
					transformOrigin: '115.116px 315.49px',
					animation: '1s 1 forwards cubic-bezier(.36,-.01,.5,1.38) zoomIn',
					animationDelay: '0s',
				}}
			>
				<path
					id="prefix__elddcu0umfdje"
					fill="#fff"
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M109.06 264.37v-8.33h11.88v8.95l-11.88-.62z"
					className="prefix__animable"
					style={{
						transformOrigin: '115px 260.515px',
					}}
				/>
				<path
					id="prefix__ell8xllyeokr"
					fill="#fff"
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M103.19 245.39s-1.85-.47-1.85 2.47 2.47 4.93 3.39 4.78-.46-5.71-.61-6.79-.93-.46-.93-.46z"
					className="prefix__animable"
					style={{
						transformOrigin: '103.192px 248.93px',
					}}
				/>
				<path
					id="prefix__elyghw483uxus"
					fill="#fff"
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M126.35 245.39s1.86-.47 1.86 2.47-2.47 4.93-3.4 4.78.46-5.71.62-6.79.92-.46.92-.46z"
					className="prefix__animable"
					style={{
						transformOrigin: '126.351px 248.93px',
					}}
				/>
				<path
					id="prefix__elbb5k6sjej0l"
					fill="#263238"
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M111.37 230.72s-5.25.77-7.41 5.4-.61 11.12.16 14.82 3.86 7.26 6.17 8 9.42 0 9.42 0a27 27 0 004.48-6c2.16-3.86 2.47-11.12 1.54-15.28s-4.17-8.34-6.64-8.65-4.78 2-4.78 2a4.38 4.38 0 00-2.94-.29z"
					className="prefix__animable"
					style={{
						transformOrigin: '114.542px 244.125px',
					}}
				/>
				<path
					id="prefix__elewzwi85gv8"
					fill="#fff"
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M122.64 262.83s-9-1.39-13-.77-6.48 5.55-6.48 5.55a37.06 37.06 0 0110-1.54 121.63 121.63 0 0112.5.93 14.1 14.1 0 00-3.02-4.17z"
					className="prefix__animable"
					style={{
						transformOrigin: '114.41px 264.755px',
					}}
				/>
				<path
					id="prefix__elytysiota47"
					fill="#fff"
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M88.84 277.49S71.83 304.34 71.4 307s2.18 6.55 6.76 6.11 21.6-.65 21.6-.65l.21-4.15-13.74-1.74 8.29-6.57z"
					className="prefix__animable"
					style={{
						transformOrigin: '85.662px 295.317px',
					}}
				/>
				<path
					id="prefix__elzjh5q19ovqq"
					fill="none"
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M86.23 306.52l-6.76 3.27"
					className="prefix__animable"
					style={{
						transformOrigin: '82.85px 308.155px',
					}}
				/>
				<path
					id="prefix__eltef5p03d5sp"
					fill="#fff"
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M141.17 276.41s17.22 27.93 17.66 30.54-2.18 6.55-6.77 6.11-21.59-.65-21.59-.65l-.22-4.15 13.75-1.74-8.3-6.52z"
					className="prefix__animable"
					style={{
						transformOrigin: '144.564px 294.752px',
					}}
				/>
				<path
					id="prefix__elxm3izipayj"
					fill="none"
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M143.99 306.52l6.77 3.27"
					className="prefix__animable"
					style={{
						transformOrigin: '147.375px 308.155px',
					}}
				/>
				<path
					id="prefix__el79xdodjs7he"
					fill="#fff"
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M108.13 265.14l-10.5 5.56c-5.24 2.78-8.49 5.4-8.79 6.79s5.86 45.39 5.86 45.39 4 3.9 14.51 4.94c15.75 1.54 22.54-3.4 22.54-3.4s9.42-46.31 9.42-48-11.89-7.42-16.06-9.42-8.8-3.71-16.98-1.86z"
					className="prefix__animable"
					style={{
						transformOrigin: '115px 296.233px',
					}}
				/>
				<path
					id="prefix__elgb98xwoqrtp"
					fill={theme.colors.accent}
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M85.75 335.69l18.37 58.71 5.09-.61-7.56-59.49-15.9 1.39z"
					className="prefix__animable"
					style={{
						transformOrigin: '97.48px 364.35px',
					}}
				/>
				<path
					id="prefix__eleed8qmc4jia"
					fill="#fff"
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M104.12 394.4l1.2 6.71a1.09 1.09 0 001.21.88l3.73-.48a1.08 1.08 0 00.89-1.39l-1.94-6.33z"
					className="prefix__animable"
					style={{
						transformOrigin: '107.659px 397.894px',
					}}
				/>
				<path
					id="prefix__eldt2qh7a5kfp"
					fill={theme.colors.accent}
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M142.38 335.69l-18.37 58.71-5.09-.61 7.56-59.49 15.9 1.39z"
					className="prefix__animable"
					style={{
						transformOrigin: '130.65px 364.35px',
					}}
				/>
				<path
					id="prefix__el2vuht4y97zs"
					fill="#fff"
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M124 394.4l-1.2 6.71a1.09 1.09 0 01-1.21.88l-3.73-.48a1.08 1.08 0 01-.89-1.39l1.94-6.33z"
					className="prefix__animable"
					style={{
						transformOrigin: '120.461px 397.894px',
					}}
				/>
				<path
					id="prefix__elhp8bo1livu"
					fill={theme.colors.accent}
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M110.45 328.74S93.62 323.5 90.53 325s-6.17 5.56-5.09 10 10.34 9.88 12.35 10 12.81 0 12.81 0z"
					className="prefix__animable"
					style={{
						transformOrigin: '97.9098px 334.892px',
					}}
				/>
				<path
					id="prefix__elcb7zx3nscmj"
					fill={theme.colors.accent}
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M118.72 328.74s16.82-5.24 19.91-3.7 6.18 5.56 5.09 10-10.34 9.88-12.35 10-12.81 0-12.81 0z"
					className="prefix__animable"
					style={{
						transformOrigin: '131.252px 334.925px',
					}}
				/>
				<path
					id="prefix__elu5uzfhsdnjs"
					fill={theme.colors.accent}
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M94.7 322.88s-1.39 15.44-.93 17.44 5.25 5.25 15 5.56 21.92.77 24.85-2.78-1.85-18.68-1.85-18.68-25.95 2.16-37.07-1.54z"
					className="prefix__animable"
					style={{
						transformOrigin: '114.124px 334.484px',
					}}
				/>
				<path
					id="prefix__el1zhr0kliz10i"
					fill="#263238"
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M94.48 401.29h0a1.08 1.08 0 01-1-1.16l3.16-58.38 3.64.3-4.64 58.25a1.08 1.08 0 01-1.16.99z"
					className="prefix__animable"
					style={{
						transformOrigin: '96.8784px 371.522px',
					}}
				/>
				<path
					id="prefix__elglmopj7vyhi"
					fill="#263238"
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M133.72 401.29h0a1.08 1.08 0 001-1.16l-3.16-58.38-3.64.3 4.65 58.25a1.08 1.08 0 001.15.99z"
					className="prefix__animable"
					style={{
						transformOrigin: '131.322px 371.521px',
					}}
				/>
				<path
					id="prefix__el4mfo5hwhgsq"
					fill="#263238"
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M137.42 295.8a176.59 176.59 0 01-24.59 1.81 171.08 171.08 0 01-23-1.81 1.86 1.86 0 00-2.13 2.13c1.18 7.37 4.53 28.6 4.53 32.67 0 4.94-1.7 9.57-1.7 11.73s.93 3.86 2.47 4.48c.72.28 8.81.3 19.75.24 12.66.06 20.76 0 21.47-.24 1.54-.62 2.47-2.32 2.47-4.48s-1.7-6.79-1.7-11.73c0-4.07 3.35-25.31 4.53-32.68a1.84 1.84 0 00-2.1-2.12zM129.5 341c-.55.21-6.79.23-16.56.19-8.43 0-14.66 0-15.21-.19a3.45 3.45 0 01-1.91-3.42c0-1.65 1.31-5.18 1.31-8.95 0-.89-.21-2.85-.53-5.32l3.09 3.34a4.53 4.53 0 003.31 1.45h21.2a4.47 4.47 0 003.15-1.29l3.23-3.14c-.3 2.3-.49 4.11-.49 5 0 3.77 1.31 7.3 1.31 8.95a3.45 3.45 0 01-1.9 3.38zm4.08-37.31c-.51 3.2-1.57 9.81-2.39 15.49l-3.13 1.91a11 11 0 01-5.73 1.6h-17.22a11 11 0 01-5.58-1.52L96 319.14c-.81-5.67-1.86-12.23-2.38-15.42a1.43 1.43 0 011.64-1.62 132.46 132.46 0 0017.74 1.38 136.83 136.83 0 0019-1.38 1.42 1.42 0 011.58 1.62z"
					className="prefix__animable"
					style={{
						transformOrigin: '113.611px 321.427px',
					}}
				/>
			</g>
			<g
				id="prefix__Floor--inject-36"
				className="prefix__animable"
				style={{
					transformOrigin: '250.5px 402px',
					animation: '1s 1 forwards cubic-bezier(.36,-.01,.5,1.38) slideRight',
					animationDelay: '0s',
				}}
			>
				<path
					id="prefix__elaodnhazb3b7"
					fill="none"
					stroke="#263238"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M16 402h469"
					className="prefix__animable"
					style={{
						transformOrigin: '250.5px 402px',
					}}
				/>
			</g>
			<defs>
				<filter id="prefix__active" height="200%">
					<feMorphology in="SourceAlpha" operator="dilate" radius={2} result="DILATED" />
					<feFlood floodColor="#32DFEC" floodOpacity={1} result="PINK" />
					<feComposite in="PINK" in2="DILATED" operator="in" result="OUTLINE" />
					<feMerge>
						<feMergeNode in="OUTLINE" />
						<feMergeNode in="SourceGraphic" />
					</feMerge>
				</filter>
				<filter id="prefix__hover" height="200%">
					<feMorphology in="SourceAlpha" operator="dilate" radius={2} result="DILATED" />
					<feFlood floodColor="red" floodOpacity={0.5} result="PINK" />
					<feComposite in="PINK" in2="DILATED" operator="in" result="OUTLINE" />
					<feMerge>
						<feMergeNode in="OUTLINE" />
						<feMergeNode in="SourceGraphic" />
					</feMerge>
					<feColorMatrix values="0 0 0 0 0 0 1 0 0 0 0 0 0 0 0 0 0 0 1 0" />
				</filter>
			</defs>
		</svg>
	)
}

export default Workshop
