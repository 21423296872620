import * as React from 'react'
import { useTheme } from '@theme'
import { Icon } from '@types'

const Creative: Icon = (props) => {
	const { theme } = useTheme()

	return (
		<svg className="prefix__animated" viewBox="0 0 500 500" {...props}>
			<style>
				{
					'@keyframes slideDown{0%{opacity:0;transform:translateY(-30px)}}@keyframes zoomIn{0%{opacity:0;transform:scale(.5)}}@keyframes zoomOut{0%{opacity:0;transform:scale(1.5)}}@keyframes slideLeft{0%{opacity:0;transform:translateX(-30px)}}@keyframes slideRight{0%{opacity:0;transform:translateX(30px)}}svg:not(.prefix__animated) .prefix__animable{opacity:0}'
				}
			</style>
			<g
				id="prefix__background-complete--inject-2"
				className="prefix__animable"
				style={{
					transformOrigin: '276.98px 227.116px',
					animation: '1s 1 forwards cubic-bezier(.36,-.01,.5,1.38) slideDown',
					animationDelay: '0s',
				}}
			>
				<path
					id="prefix__el1yrvy0lq30j"
					fill="#e0e0e0"
					d="M332.18 92.08h65.75s-5.81-10.58-22.74-9c-16.16 1.47-17-5.21-25.2-6s-15.07 5.41-17.81 15z"
					className="prefix__animable"
					style={{
						transformOrigin: '365.055px 84.5462px',
					}}
				/>
				<path
					id="prefix__eltkm3c774wes"
					fill="#ebebeb"
					d="M160.32 265.9s-6.59 3.56 3 17.32 15.83 20.36 15.83 20.36 1.82 3.25-1 4.18-9.33.8-10.13 4.51c-.83 3.86 2.32 7 8.14 9.25s18.4 7.78 19.53 8.28 2.37 1.95.55 2.89-15.93 4.41-13.89 11 19.32 10.32 21.2 10.76 2.41 1.14 2.4 2.14-4.86 1.72-8.84 2.87-8.38 3.19-6.88 6.9 12.36 10.44 36.34 9.49l11.08-1.16 4.72-10.15c8.77-22.33 6-34.8 3-37.44s-6.38.86-8.77 4.24-4.67 7.72-5.62 7.4-1.43-1.05-1.23-3 2.37-19.46-3.19-23.56-13.48 8.08-15 9.49-2.45-.25-2.55-1.47-1.15-14.91-1.4-21.17-2.14-10.24-6.05-10.72-5.78 5.72-7.59 8.08-4.28-.43-4.28-.43-4.19-8.06-14-21.64-15.5-8.32-15.5-8.32"
					className="prefix__animable"
					style={{
						transformOrigin: '203.198px 320.469px',
					}}
				/>
				<path
					id="prefix__el5gwr81r23d2"
					fill="#e0e0e0"
					d="M238.58 377.22c-7.19-10-19.66-28-30.45-43.39s-20.56-29.31-27.7-39.35c-3.57-5-6.45-9.06-8.46-11.87l-2.3-3.24-.59-.84c-.13-.19-.19-.3-.19-.3s.08.09.22.28l.62.82 2.35 3.2c2 2.77 5 6.81 8.55 11.81 7.19 10 17 23.9 27.78 39.29s23.23 33.41 30.37 43.44"
					className="prefix__animable"
					style={{
						transformOrigin: '203.835px 327.725px',
					}}
				/>
				<path
					id="prefix__elzebjq0ka15"
					fill="#e0e0e0"
					d="M177.12 312.83a4.23 4.23 0 01.8.11c.51.09 1.24.24 2.15.44 1.81.39 4.29 1 7 1.72l7 1.87 2.12.56a3.31 3.31 0 01.77.23 3.63 3.63 0 01-.8-.1c-.51-.09-1.24-.24-2.15-.45-1.8-.41-4.28-1-7-1.77l-7-1.82-2.12-.55a3 3 0 01-.77-.24z"
					className="prefix__animable"
					style={{
						transformOrigin: '187.04px 315.295px',
					}}
				/>
				<path
					id="prefix__el0o8renrlvw8"
					fill="#e0e0e0"
					d="M197.62 317c-.1 0 .93-5.2 2.29-11.56s2.55-11.51 2.64-11.49-.93 5.19-2.3 11.56-2.54 11.49-2.63 11.49z"
					className="prefix__animable"
					style={{
						transformOrigin: '200.084px 305.475px',
					}}
				/>
				<path
					id="prefix__el0p7410wj9i6"
					fill="#e0e0e0"
					d="M213.12 341.33a3.35 3.35 0 01.35-.74l1.06-2c.89-1.66 2.12-4 3.37-6.56s2.33-5 3.08-6.69l.9-2.05a2.83 2.83 0 01.37-.74 4.48 4.48 0 01-.24.79c-.18.5-.45 1.21-.8 2.09-.7 1.76-1.73 4.16-3 6.76s-2.53 4.88-3.47 6.52c-.48.81-.88 1.47-1.16 1.91a4.44 4.44 0 01-.46.71z"
					className="prefix__animable"
					style={{
						transformOrigin: '217.685px 331.94px',
					}}
				/>
				<path
					id="prefix__eluthr5cwp3h"
					fill="#e0e0e0"
					d="M193.76 341.69a2.52 2.52 0 01.75-.09l2.07-.12c1.74-.09 4.15-.18 6.81-.27s5.07-.14 6.82-.16h2.07a4.39 4.39 0 01.76 0 2.82 2.82 0 01-.76.1l-2.06.12c-1.75.08-4.16.18-6.82.26s-5.07.14-6.82.16h-2.06a3.26 3.26 0 01-.76 0z"
					className="prefix__animable"
					style={{
						transformOrigin: '203.4px 341.373px',
					}}
				/>
				<path
					id="prefix__el46sbczcts3d"
					fill="#e0e0e0"
					d="M204.71 365a4.59 4.59 0 011-.11l2.65-.16c2.23-.12 5.32-.27 8.73-.41s6.5-.24 8.73-.3l2.65-.06a6.28 6.28 0 011 0 6.15 6.15 0 01-1 .11l-2.65.16c-2.23.12-5.32.27-8.73.41s-6.5.24-8.73.3l-2.65.06a6.09 6.09 0 01-1 0z"
					className="prefix__animable"
					style={{
						transformOrigin: '217.09px 364.48px',
					}}
				/>
				<path
					id="prefix__elep905mfdwyt"
					fill="#e0e0e0"
					d="M228.81 363.8a6.17 6.17 0 01.42-.95l1.26-2.55c1.06-2.15 2.5-5.12 4-8.45s2.8-6.37 3.72-8.59l1.09-2.62a6.91 6.91 0 01.43-.94 5.18 5.18 0 01-.31 1c-.22.64-.55 1.55-1 2.66-.86 2.24-2.12 5.31-3.63 8.64s-3 6.3-4.11 8.42c-.56 1.06-1 1.92-1.36 2.5a6.8 6.8 0 01-.51.88z"
					className="prefix__animable"
					style={{
						transformOrigin: '234.27px 351.75px',
					}}
				/>
				<path
					id="prefix__el7ihg0wl16y3"
					fill="#f5f5f5"
					d="M321.8 216.22c-11.9-13.35-10.76-32.62-6.25-49.11.75-2.76 1.14-5.76 3-7.88s5.38-3.06 7.59-1.3c1.83 1.46 2.16 4.07 2.84 6.33a16.69 16.69 0 005 7.76c1.55 1.34 3.6 2.41 5.58 1.87 2.71-.76 3.77-4 4.41-6.77q1.77-7.73 3.56-15.46a26.84 26.84 0 012.77-8c1.48-2.42 4-4.4 6.76-4.51s5.69 2.17 5.6 5a26.31 26.31 0 01-.73 3.46 3 3 0 001.05 3.13c1 .59 2.31.07 3.33-.55 3.51-2.11 6.3-5.24 8.95-8.39s5.27-6.38 8.58-8.81 7.45-4 11.46-3.28 7.64 4.16 7.72 8.27-3.12 7.55-6.34 10.11a63.37 63.37 0 01-15.68 9.19 5.18 5.18 0 00-2.69 1.8c-1 1.7.27 3.94 1.94 5 1.9 1.17 4.18 1.49 6.28 2.25s4.2 2.27 4.53 4.49c.46 3.09-2.6 5.49-5.38 6.88a54.43 54.43 0 01-17.74 5.3c-2.21.26-4.5.41-6.48 1.44s-3.55 3.29-3 5.47 2.92 3.41 5.14 3.51 4.4-.59 6.61-.87c4.13-.54 9 .88 10.62 4.74a9 9 0 01-.9 8 20.64 20.64 0 01-5.8 5.83 54.6 54.6 0 01-26.44 10.56c-9.52 1-17.88-.14-25.87-5.41"
					className="prefix__animable"
					style={{
						transformOrigin: '357.694px 175.771px',
					}}
				/>
				<path
					id="prefix__eljvqrzhtjhy"
					fill="#e0e0e0"
					d="M324.22 217.82c2.16-6.44-.1-3.21 4.35-11a254.3 254.3 0 0116.13-24.32q2.3-3.12 4.55-6.15c1.5-2 3-4 4.56-5.89a128.52 128.52 0 019.67-10.2c3.23-3.1 6.41-5.92 9.41-8.54s5.84-5 8.52-7.05a101.56 101.56 0 0113.37-8.77c1.65-.91 3-1.57 3.85-2l1-.49.35-.15a1.93 1.93 0 01-.33.2l-1 .54c-.88.47-2.17 1.16-3.8 2.09a105.85 105.85 0 00-13.22 8.91c-2.66 2.07-5.48 4.46-8.46 7.08s-6.14 5.46-9.36 8.56a131.24 131.24 0 00-9.61 10.18c-1.56 1.86-3 3.85-4.53 5.87l-4.55 6.15A261.69 261.69 0 00329 207.05c-4.46 7.77-2.22 4.49-4.42 10.89"
					className="prefix__animable"
					style={{
						transformOrigin: '362.1px 175.6px',
					}}
				/>
				<path
					id="prefix__el2ojnggyuc7c"
					fill="#e0e0e0"
					d="M354 170.48a6.27 6.27 0 010-1.24c0-.8.13-2 .26-3.37.26-2.85.76-6.76 1.32-11.07s1-8.22 1.21-11.06c.11-1.42.19-2.56.24-3.36a5.41 5.41 0 01.13-1.23 6.09 6.09 0 01.07 1.24c0 .79 0 2-.07 3.37-.12 2.86-.5 6.79-1.07 11.11s-1.07 8.1-1.46 11c-.17 1.33-.31 2.45-.42 3.35a5.77 5.77 0 01-.21 1.26z"
					className="prefix__animable"
					style={{
						transformOrigin: '355.603px 154.815px',
					}}
				/>
				<path
					id="prefix__eldihu8on5i2p"
					fill="#e0e0e0"
					d="M391 172.25a7.07 7.07 0 01-1.45-.16c-.93-.14-2.28-.34-3.95-.54-3.33-.42-8-.83-13.08-1.07s-9.77-.24-13.14-.15c-1.68 0-3 .1-4 .14a8.78 8.78 0 01-1.47 0 7.94 7.94 0 011.46-.22c.94-.1 2.3-.22 4-.3 3.37-.19 8-.24 13.18 0a131 131 0 0113.11 1.23c1.66.25 3 .51 3.93.7a7.59 7.59 0 011.41.37z"
					className="prefix__animable"
					style={{
						transformOrigin: '372.455px 171.019px',
					}}
				/>
				<path
					id="prefix__elrbxqopvrtb"
					fill="#e0e0e0"
					d="M329.16 206.26a1.56 1.56 0 01-.14-.49c-.08-.39-.18-.86-.31-1.43-.25-1.24-.58-3-.95-5.28-.75-4.47-1.59-10.68-2.43-17.55s-1.62-13.08-2.27-17.56c-.32-2.18-.59-4-.79-5.3-.08-.58-.15-1.06-.21-1.45a2.77 2.77 0 010-.51 1.88 1.88 0 01.15.49l.3 1.43c.26 1.24.58 3 .95 5.28.75 4.47 1.59 10.68 2.43 17.55s1.62 13.08 2.27 17.56c.33 2.18.59 4 .79 5.31.09.57.15 1.05.21 1.44a2 2 0 010 .51z"
					className="prefix__animable"
					style={{
						transformOrigin: '325.612px 181.475px',
					}}
				/>
				<path
					id="prefix__el4w84hswoiol"
					fill="#e0e0e0"
					d="M379.93 202.23a1.68 1.68 0 01-.51.08l-1.48.14-5.44.44c-4.59.37-10.93 1-17.93 1.62s-13.36 1.19-18 1.47c-2.3.14-4.16.23-5.45.27h-1.48a1.76 1.76 0 01-.52 0 2.05 2.05 0 01.51-.08l1.48-.14 5.44-.44c4.59-.37 10.93-1 17.93-1.62s13.35-1.19 18-1.46c2.31-.14 4.17-.24 5.46-.28h1.48a2.09 2.09 0 01.51 0z"
					className="prefix__animable"
					style={{
						transformOrigin: '354.525px 204.242px',
					}}
				/>
				<path
					id="prefix__elggtl9exzv1"
					fill="#e0e0e0"
					d="M419.31 304a2.15 2.15 0 01-.16.47l-.55 1.33c-.51 1.2-1.23 2.89-2.13 5l-7.71 17.83-.05.1h-.11l-24.22 4-8.12 1.33-.37.06.09-.36c2-7.79 3.79-15 5.34-21.05l.11.23-14.23-6.36-3.93-1.79-1-.48a1.79 1.79 0 01-.34-.19l.36.13 1.05.43 4 1.69 14.3 6.2.16.07v.16c-1.52 6.1-3.31 13.28-5.25 21.08l-.28-.3 8.12-1.34 24.23-3.94-.17.13 7.87-17.75c.95-2.12 1.71-3.8 2.25-5l.6-1.31a3.31 3.31 0 01.14-.37z"
					className="prefix__animable"
					style={{
						transformOrigin: '390.62px 319.06px',
					}}
				/>
				<path
					id="prefix__elmor09cdjaoi"
					fill="#e0e0e0"
					d="M379.1 331.25l-.67 1.74 1.06 1.54-1.86-.09-1.13 1.48-.49-1.8-1.76-.62 1.56-1.02.05-1.86 1.45 1.17 1.79-.54z"
					className="prefix__animable"
					style={{
						transformOrigin: '376.87px 333.27px',
					}}
				/>
				<path
					id="prefix__eltx0npitc5l"
					fill="#e0e0e0"
					d="M362.47 303l-.33.87.52.76-.92-.05-.56.74-.25-.89-.87-.31.77-.51.03-.93.72.58.89-.26z"
					className="prefix__animable"
					style={{
						transformOrigin: '361.36px 304px',
					}}
				/>
				<path
					id="prefix__elyweifiecrzo"
					fill="#e0e0e0"
					d="M409.44 327.56l-.33.87.52.76-.92-.04-.56.74-.25-.9-.87-.3.77-.51.02-.93.73.58.89-.27z"
					className="prefix__animable"
					style={{
						transformOrigin: '408.33px 328.57px',
					}}
				/>
				<path
					id="prefix__elx57kpdu7ldj"
					fill="#e0e0e0"
					d="M382.33 311.97l-.33.86.53.77-.93-.05-.56.74-.24-.9-.88-.3.78-.51.02-.93.72.58.89-.26z"
					className="prefix__animable"
					style={{
						transformOrigin: '381.225px 312.97px',
					}}
				/>
				<path
					id="prefix__eljv2ajwhawzp"
					fill="#e0e0e0"
					d="M420.44 303.13l-.33.87.53.76-.93-.04-.56.74-.24-.9-.88-.31.78-.5.02-.93.72.58.89-.27z"
					className="prefix__animable"
					style={{
						transformOrigin: '419.335px 304.14px',
					}}
				/>
				<path
					id="prefix__eld5rr655gip6"
					fill="#e0e0e0"
					d="M117.34 375.91h52.36s-4.63-8.43-18.11-7.2c-12.87 1.17-13.52-4.15-20.07-4.8s-11.99 4.36-14.18 12z"
					className="prefix__animable"
					style={{
						transformOrigin: '143.52px 369.882px',
					}}
				/>
				<path
					id="prefix__el52gc37b9pd2"
					fill="#f5f5f5"
					d="M127.74 169.79h52.35s-4.63-8.42-18.11-7.2c-12.86 1.18-13.52-4.14-20.06-4.79s-12 4.36-14.18 11.99z"
					className="prefix__animable"
					style={{
						transformOrigin: '153.915px 163.767px',
					}}
				/>
				<path
					id="prefix__ellu8u1yk7q1"
					fill="#f5f5f5"
					d="M393.62 206.2h43s-3.8-6.92-14.87-5.92c-10.58 1-11.12-3.4-16.49-3.94s-9.85 3.59-11.64 9.86z"
					className="prefix__animable"
					style={{
						transformOrigin: '415.12px 201.246px',
					}}
				/>
			</g>
			<g
				id="prefix__Pencil--inject-2"
				className="prefix__animable"
				style={{
					transformOrigin: '245.697px 231.243px',
					animation: '1s 1 forwards cubic-bezier(.36,-.01,.5,1.38) zoomIn',
					animationDelay: '0s',
				}}
			>
				<path
					id="prefix__el4unem3o4hkr"
					fill={theme.colors.accent}
					d="M307.73 291.09c2.14-16.11-9.19-29.41-23.35-37.4s-30.59-10.69-46.23-15.11c-14.75-4.19-29.42-10.31-40.34-21.07s-17.48-26.93-13.56-41.75c1-3.72 2.75-7.46 5.95-9.61s8.07-2.09 10.46.93c5.08 6.44-4.82 18 1.38 23.34 1.42 1.24 3.38 1.69 4.85 2.86 3.91 3.13 2.65 9.2 3.2 14.18 1.09 9.85 10.33 16.69 19.4 20.68 12.15 5.34 25.31 7.85 38.19 11s25.89 7.16 36.62 15 20.06 20 20 33.29c0 3.71-.49 8.27-2.82 11.15s-5.29 3.49-8.65 1.91-5.7-4-5.08-9.38"
					className="prefix__animable"
					style={{
						transformOrigin: '253.703px 233px',
					}}
				/>
				<g
					id="prefix__eldqmsl6pf4a"
					className="prefix__animable"
					opacity={0.8}
					style={{
						transformOrigin: '253.703px 233px',
					}}
				>
					<path
						id="prefix__elgllwzewktkh"
						fill="#fff"
						d="M307.73 291.09c2.14-16.11-9.19-29.41-23.35-37.4s-30.59-10.69-46.23-15.11c-14.75-4.19-29.42-10.31-40.34-21.07s-17.48-26.93-13.56-41.75c1-3.72 2.75-7.46 5.95-9.61s8.07-2.09 10.46.93c5.08 6.44-4.82 18 1.38 23.34 1.42 1.24 3.38 1.69 4.85 2.86 3.91 3.13 2.65 9.2 3.2 14.18 1.09 9.85 10.33 16.69 19.4 20.68 12.15 5.34 25.31 7.85 38.19 11s25.89 7.16 36.62 15 20.06 20 20 33.29c0 3.71-.49 8.27-2.82 11.15s-5.29 3.49-8.65 1.91-5.7-4-5.08-9.38"
						className="prefix__animable"
						style={{
							transformOrigin: '253.703px 233px',
						}}
					/>
				</g>
				<path
					id="prefix__ela8qcuzkwrzu"
					fill={theme.colors.accent}
					d="M239.23 163c-.51-6 3.94-11.17 9-13.95s10.86-3.92 16.07-6.39c12.95-6.14 21.18-20.59 21.79-35.4s-5.82-29.63-15.86-40.51c-4.09-4.42-9.11-9.06-9.17-15.09-.06-6.3 6-11.26 12.14-11.18s11.72 4.17 15.37 9.3S294.23 61 296.2 67c-1.4-2.52 1.47-5.63 4.25-5.36s5 2.58 6.67 4.88a52.1 52.1 0 0110 34.85 49.1 49.1 0 01-15.5 31.94c-9.87 9-22.92 13.21-35.58 17.21l-26.41 8.33"
					className="prefix__animable"
					style={{
						transformOrigin: '278.23px 101.74px',
					}}
				/>
				<g
					id="prefix__el68vcbbfrbxt"
					className="prefix__animable"
					opacity={0.8}
					style={{
						transformOrigin: '278.23px 101.74px',
					}}
				>
					<path
						id="prefix__elkcyql4bz0ri"
						fill="#fff"
						d="M239.23 163c-.51-6 3.94-11.17 9-13.95s10.86-3.92 16.07-6.39c12.95-6.14 21.18-20.59 21.79-35.4s-5.82-29.63-15.86-40.51c-4.09-4.42-9.11-9.06-9.17-15.09-.06-6.3 6-11.26 12.14-11.18s11.72 4.17 15.37 9.3S294.23 61 296.2 67c-1.4-2.52 1.47-5.63 4.25-5.36s5 2.58 6.67 4.88a52.1 52.1 0 0110 34.85 49.1 49.1 0 01-15.5 31.94c-9.87 9-22.92 13.21-35.58 17.21l-26.41 8.33"
						className="prefix__animable"
						style={{
							transformOrigin: '278.23px 101.74px',
						}}
					/>
				</g>
				<path
					id="prefix__elsqjrx27wlxq"
					fill={theme.colors.accent}
					d="M231.88 37.52a5.62 5.62 0 10.92-.29"
					className="prefix__animable"
					style={{
						transformOrigin: '234.023px 42.7152px',
					}}
				/>
				<g
					id="prefix__elhxl4weyjhvh"
					className="prefix__animable"
					opacity={0.8}
					style={{
						transformOrigin: '234.023px 42.7152px',
					}}
				>
					<path
						id="prefix__el3ep2vt0bt59"
						fill="#fff"
						d="M231.88 37.52a5.62 5.62 0 10.92-.29"
						className="prefix__animable"
						style={{
							transformOrigin: '234.023px 42.7152px',
						}}
					/>
				</g>
				<path
					id="prefix__elrucw3oalwa"
					fill={theme.colors.accent}
					d="M264.45 422.84c-3.24-6.71-.41-15.08 4.75-20.46s12.2-8.45 19-11.45c15.06-6.64 31-14.54 38.65-29.1 2.58-4.91 4.05-10.31 5.5-15.67 2.81-10.36 5.45-22.13-.2-31.26-1.63-2.63-4.07-5-7.11-5.5-3.47-.62-7 1.29-9.16 4.08a20.23 20.23 0 00-3.49 9.83c-.84 6.63-.14 13.38-.84 20S308.44 357 303 360.78c-6.67 4.65-16.18 3.61-22.65 8.52-5.23 4-7.22 10.84-11.3 16-3.44 4.31-8.25 7.25-12.65 10.57s-8.66 7.36-10.16 12.67a15 15 0 0020.89 17.63"
					className="prefix__animable"
					style={{
						transformOrigin: '290.578px 368.457px',
					}}
				/>
				<g
					id="prefix__elc1xu7iuo9gl"
					className="prefix__animable"
					opacity={0.8}
					style={{
						transformOrigin: '290.578px 368.457px',
					}}
				>
					<path
						id="prefix__elv0py0334eze"
						fill="#fff"
						d="M264.45 422.84c-3.24-6.71-.41-15.08 4.75-20.46s12.2-8.45 19-11.45c15.06-6.64 31-14.54 38.65-29.1 2.58-4.91 4.05-10.31 5.5-15.67 2.81-10.36 5.45-22.13-.2-31.26-1.63-2.63-4.07-5-7.11-5.5-3.47-.62-7 1.29-9.16 4.08a20.23 20.23 0 00-3.49 9.83c-.84 6.63-.14 13.38-.84 20S308.44 357 303 360.78c-6.67 4.65-16.18 3.61-22.65 8.52-5.23 4-7.22 10.84-11.3 16-3.44 4.31-8.25 7.25-12.65 10.57s-8.66 7.36-10.16 12.67a15 15 0 0020.89 17.63"
						className="prefix__animable"
						style={{
							transformOrigin: '290.578px 368.457px',
						}}
					/>
				</g>
				<path
					id="prefix__elvdnkbcpao8a"
					fill={theme.colors.accent}
					d="M164.33 83.86a20.85 20.85 0 01.6-33.91c3.08-2.11 6.72-3.41 9.46-6 2.43-2.26 4-5.39 6.73-7.29A9.8 9.8 0 01196 48c-1.25 3.51-4.4 6.08-5.72 9.56-1.42 3.76-.5 8-1 11.94a16.49 16.49 0 01-22.08 13.28"
					className="prefix__animable"
					style={{
						transformOrigin: '176.249px 59.357px',
					}}
				/>
				<g
					id="prefix__el2rs9o66t9hl"
					className="prefix__animable"
					opacity={0.8}
					style={{
						transformOrigin: '176.249px 59.357px',
					}}
				>
					<path
						id="prefix__elgb59sqfzq9s"
						fill="#fff"
						d="M164.33 83.86a20.85 20.85 0 01.6-33.91c3.08-2.11 6.72-3.41 9.46-6 2.43-2.26 4-5.39 6.73-7.29A9.8 9.8 0 01196 48c-1.25 3.51-4.4 6.08-5.72 9.56-1.42 3.76-.5 8-1 11.94a16.49 16.49 0 01-22.08 13.28"
						className="prefix__animable"
						style={{
							transformOrigin: '176.249px 59.357px',
						}}
					/>
				</g>
				<path
					id="prefix__elg46o360ih95"
					fill={theme.colors.accent}
					d="M164.71 62.42H177v25.33a5.41 5.41 0 01-5.41 5.41h-1.46a5.41 5.41 0 01-5.41-5.41V62.42h-.01z"
					className="prefix__animable"
					style={{
						transformOrigin: '170.855px 77.79px',
					}}
				/>
				<path
					id="prefix__elhkbldpztncu"
					fill="#ffbe9d"
					d="M164.71 62.42l6.14-11.27L177 62.42h-12.29z"
					className="prefix__animable"
					style={{
						transformOrigin: '170.855px 56.785px',
					}}
				/>
				<path
					id="prefix__el2x7dsvkpgpk"
					fill={theme.colors.accent}
					d="M168.42 55.61s1.87 2.81 4.87 0l-2.43-4.46z"
					className="prefix__animable"
					style={{
						transformOrigin: '170.855px 54.0044px',
					}}
				/>
				<path
					id="prefix__elzk9ab04hkjb"
					fill="#263238"
					d="M177.17 86.52a39 39 0 01-6.25.25 38.85 38.85 0 01-6.24-.25 38.85 38.85 0 016.24-.25 39 39 0 016.25.25z"
					className="prefix__animable"
					style={{
						transformOrigin: '170.925px 86.52px',
					}}
				/>
				<path
					id="prefix__elzwq58dyqwtg"
					fill="#263238"
					d="M177 84.63a37.41 37.41 0 01-6.12.25 37.41 37.41 0 01-6.12-.25 39.6 39.6 0 016.12-.24 39.6 39.6 0 016.12.24z"
					className="prefix__animable"
					style={{
						transformOrigin: '170.88px 84.6364px',
					}}
				/>
				<path
					id="prefix__els5g4qtf9bqn"
					fill="#fafafa"
					d="M168.32 62.6c.13 0 .25 4.9.25 10.95s-.12 11-.25 11-.25-4.91-.25-11 .11-10.95.25-10.95z"
					className="prefix__animable"
					style={{
						transformOrigin: '168.32px 73.575px',
					}}
				/>
				<path
					id="prefix__el4szraahxyf"
					fill="#fafafa"
					d="M173.29 62.6c.14 0 .25 4.9.25 10.95s-.11 11-.25 11-.25-4.91-.25-11 .11-10.95.25-10.95z"
					className="prefix__animable"
					style={{
						transformOrigin: '173.29px 73.575px',
					}}
				/>
			</g>
			<g
				id="prefix__Notebook--inject-2"
				className="prefix__animable"
				style={{
					transformOrigin: '270.625px 436.733px',
					animation: '1s 1 forwards cubic-bezier(.36,-.01,.5,1.38) zoomOut',
					animationDelay: '0s',
				}}
			>
				<path
					id="prefix__eldwe2xxy80m8"
					fill="#263238"
					d="M271.49 408.72l55.83 2.39 1.35 3.25-36.6 59.4-66.06-1.93 45.48-63.11z"
					className="prefix__animable"
					style={{
						transformOrigin: '277.34px 441.24px',
					}}
				/>
				<path
					id="prefix__el77uiim2cgn6"
					fill="#e0e0e0"
					d="M223.51 466.04l66.45 3.8 34.72-53.87-24.38 1.98-61.69 21.72-15.1 26.37z"
					className="prefix__animable"
					style={{
						transformOrigin: '274.095px 442.905px',
					}}
				/>
				<path
					id="prefix__elxialmhlcgll"
					fill="#ebebeb"
					d="M214.17 462.05l61 1c-2.53-7.81 3.44-18.8 11.65-22.5 5.44-2.45 15.67-5.63 20.95-8.34 10-5.15 19.1-14.89 18.31-23.26l-25.08-.83-32-1.24c-2.68 7.94-8.4 13.13-17.1 16.26-7.78 2.8-16.64 2.79-24.24 6-12.22 5.12-18.09 21.71-13.54 32.94"
					className="prefix__animable"
					style={{
						transformOrigin: '269.356px 434.965px',
					}}
				/>
				<path
					id="prefix__elx90jy76i5gr"
					fill="#263238"
					d="M214.17 462.05s-.06-.13-.15-.4l-.42-1.17a14.21 14.21 0 01-.52-2 20.31 20.31 0 01-.42-2.78 28.56 28.56 0 01.48-7.76 29 29 0 013.92-9.68 23.55 23.55 0 011.75-2.46c.33-.39.65-.82 1-1.2l1.13-1.15a10.55 10.55 0 011.22-1.09c.43-.35.85-.72 1.31-1.05a22.44 22.44 0 013-1.78c4.23-2.13 9.28-3 14.54-4 2.64-.48 5.36-1 8.08-1.73a37.79 37.79 0 008.05-3.24 26.58 26.58 0 003.75-2.46 22.52 22.52 0 003.33-3.15 24 24 0 004.64-8.18l.06-.17h.17l26.65 1c9.25.36 18.82.61 28.62.94l1.78.06h.22v.22a13.62 13.62 0 01-.8 5.64 21.44 21.44 0 01-1.12 2.6 25.14 25.14 0 01-1.46 2.4 37.13 37.13 0 01-7.56 8 44.85 44.85 0 01-9.13 5.63c-3.22 1.45-6.48 2.63-9.67 3.81s-6.35 2.3-9.34 3.6a18.66 18.66 0 00-4.11 2.5c-.65.46-1.19 1.05-1.78 1.56s-1.1 1.13-1.57 1.76a22.66 22.66 0 00-4.27 8 14.9 14.9 0 00-.15 8.54l.09.27h-.28l-44.39-.78-12.31-.25-3.22-.09h-1.11 4.34l12.31.14 44.38.62-.19.26a15.2 15.2 0 01.13-8.78 22.85 22.85 0 014.33-8.18c.47-.64 1.06-1.19 1.59-1.79s1.16-1.13 1.82-1.61a18.48 18.48 0 014.2-2.55c3-1.32 6.18-2.45 9.37-3.63s6.44-2.37 9.63-3.81a43.86 43.86 0 009-5.58 36.53 36.53 0 007.46-7.88 26.92 26.92 0 001.45-2.32 21 21 0 001.09-2.53 13.21 13.21 0 00.78-5.43l.24.22-1.79-.05c-9.8-.33-19.37-.59-28.61-1l-26.66-1 .23-.16a24.68 24.68 0 01-4.74 8.33 22.8 22.8 0 01-3.4 3.2 26 26 0 01-3.82 2.49 37.53 37.53 0 01-8.14 3.26c-2.75.75-5.48 1.25-8.12 1.72-5.26.94-10.29 1.82-14.46 3.9a21.76 21.76 0 00-2.93 1.74c-.46.32-.87.69-1.3 1a10.37 10.37 0 00-1.21 1.08l-1.16 1.15c-.36.38-.67.8-1 1.19a22.1 22.1 0 00-1.75 2.42 28.6 28.6 0 00-3.93 9.58 28.07 28.07 0 00-.55 7.7 19.33 19.33 0 00.38 2.77 14.24 14.24 0 00.48 2c.17.52.29.91.38 1.19s.16.38.16.38z"
					className="prefix__animable"
					style={{
						transformOrigin: '269.487px 434.865px',
					}}
				/>
				<path
					id="prefix__elvt4qg2djzr9"
					fill="#263238"
					d="M286.19 407.87s.08-.39.14-1.09a6.15 6.15 0 00-.48-2.88 5.91 5.91 0 00-2.88-3.1 6.6 6.6 0 00-8.86 3.4 7.39 7.39 0 00-.59 4.25 7.28 7.28 0 001 2.74c.38.6.66.88.63.91a2.88 2.88 0 01-.78-.8 6.69 6.69 0 01-1.23-2.79 7.51 7.51 0 01.51-4.5 7 7 0 019.48-3.62 6.16 6.16 0 013 3.38 5.85 5.85 0 01.34 3 4.29 4.29 0 01-.2.81c-.02.21-.06.29-.08.29z"
					className="prefix__animable"
					style={{
						transformOrigin: '279.767px 405.903px',
					}}
				/>
				<path
					id="prefix__el3wvyk18ywb5"
					fill="#263238"
					d="M295.76 407.87c-.05 0 .08-.39.14-1.09a6.28 6.28 0 00-.48-2.88 5.94 5.94 0 00-2.89-3.1 6.61 6.61 0 00-8.86 3.4 7.38 7.38 0 00-.58 4.25 7.12 7.12 0 001 2.74c.38.6.66.88.63.91a2.67 2.67 0 01-.79-.8 6.82 6.82 0 01-1.23-2.79 7.6 7.6 0 01.52-4.5 7 7 0 019.47-3.62 6.16 6.16 0 013 3.38 6 6 0 01.35 3 3.55 3.55 0 01-.21.81.71.71 0 01-.07.29z"
					className="prefix__animable"
					style={{
						transformOrigin: '289.332px 405.905px',
					}}
				/>
				<path
					id="prefix__elgsmtpzus4fq"
					fill="#263238"
					d="M303 408.37s.06-.4.08-1.1a6.23 6.23 0 00-.64-2.85 5.92 5.92 0 00-3.06-2.92 6.66 6.66 0 00-5.14.1 6.74 6.74 0 00-3.51 3.78 7.43 7.43 0 00-.35 4.28 7.17 7.17 0 001.2 2.68c.41.57.71.84.68.87a2.68 2.68 0 01-.83-.75 6.76 6.76 0 01-1.39-2.72 7.59 7.59 0 01.26-4.52 7 7 0 019.26-4.15 6.16 6.16 0 013.19 3.2 5.86 5.86 0 01.52 3 3.57 3.57 0 01-.16.82c-.03.19-.11.28-.11.28z"
					className="prefix__animable"
					style={{
						transformOrigin: '296.553px 406.878px',
					}}
				/>
				<path
					id="prefix__eleho6r28l2wd"
					fill="#263238"
					d="M310.81 408.81v-1.1a6.12 6.12 0 00-.79-2.81 5.9 5.9 0 00-3.21-2.76 6.6 6.6 0 00-8.43 4.34 7.36 7.36 0 00-.12 4.29 7.27 7.27 0 001.34 2.62c.44.54.75.79.72.83a2.8 2.8 0 01-.87-.71 6.81 6.81 0 01-1.53-2.64 7.59 7.59 0 010-4.53 7.08 7.08 0 013.51-4.26 7 7 0 015.52-.37 6.2 6.2 0 013.36 3 5.9 5.9 0 01.67 3 4.16 4.16 0 01-.11.82c-.01.19-.04.28-.06.28z"
					className="prefix__animable"
					style={{
						transformOrigin: '304.28px 407.765px',
					}}
				/>
				<path
					id="prefix__elnzvgalskin"
					fill="#263238"
					d="M318.61 408.63v-1.1a6.13 6.13 0 00-.87-2.79 5.93 5.93 0 00-3.28-2.67 6.59 6.59 0 00-8.31 4.58 7.31 7.31 0 001.41 6.87c.46.53.78.77.75.8a2.55 2.55 0 01-.89-.67 6.92 6.92 0 01-1.6-2.6 7.57 7.57 0 01-.11-4.53 7.11 7.11 0 013.39-4.36 7 7 0 015.5-.52 6.22 6.22 0 013.45 2.92 5.93 5.93 0 01.75 3 3.92 3.92 0 01-.09.83c-.05.14-.08.24-.1.24z"
					className="prefix__animable"
					style={{
						transformOrigin: '312.108px 407.812px',
					}}
				/>
				<path
					id="prefix__el3iqotskupi3"
					fill="#263238"
					d="M325.19 409.5v-1.1a6.11 6.11 0 00-.76-2.82 5.93 5.93 0 00-3.17-2.79 6.59 6.59 0 00-8.48 4.24 7.35 7.35 0 001.13 6.92c.44.55.74.8.72.84a2.73 2.73 0 01-.86-.72 6.67 6.67 0 01-1.5-2.66 7.55 7.55 0 01.07-4.53 7.08 7.08 0 013.56-4.22 7 7 0 015.52-.3 6.13 6.13 0 013.32 3.06 5.87 5.87 0 01.64 3 4 4 0 01-.12.82c-.02.17-.05.27-.07.26z"
					className="prefix__animable"
					style={{
						transformOrigin: '318.672px 408.361px',
					}}
				/>
			</g>
			<g
				id="prefix__air-balloon--inject-2"
				className="prefix__animable"
				style={{
					transformOrigin: '355.284px 269.108px',
					animation: '1s 1 forwards cubic-bezier(.36,-.01,.5,1.38) slideLeft',
					animationDelay: '0s',
				}}
			>
				<path
					id="prefix__el8uig37d2xas"
					fill={theme.colors.accent}
					d="M354 204.65a54.58 54.58 0 00-8.27.38 34.39 34.39 0 00-30 34.29 61.3 61.3 0 002.13 16.35c4.81 17.06 6.29 32.09 5.74 39.42l16.1 8.65s7.45-10.71 14.65-15.33 31.16-17 40.65-29.65c6.11-8.18 7.29-25-7.29-39.66A49.43 49.43 0 00354 204.65z"
					className="prefix__animable"
					style={{
						transformOrigin: '357.508px 254.183px',
					}}
				/>
				<path
					id="prefix__el7md39bp3s6n"
					fill="#455a64"
					d="M315.9 313.71l14.54 7.86-9.46 12.02-9.7-4.4 4.62-15.48z"
					className="prefix__animable"
					style={{
						transformOrigin: '320.86px 323.65px',
					}}
				/>
				<path
					id="prefix__eluklo57x15i"
					fill="#263238"
					d="M316.61 314.2c-.13-.05 1.31-4.37 3.22-9.64s3.56-9.52 3.69-9.47-1.31 4.36-3.22 9.64-3.56 9.51-3.69 9.47z"
					className="prefix__animable"
					style={{
						transformOrigin: '320.065px 304.645px',
					}}
				/>
				<path
					id="prefix__eljmd0dxxmk5"
					fill="#263238"
					d="M329.53 321c-.12-.07 2.16-4.15 5.08-9.11S340 303 340.13 303s-2.16 4.15-5.09 9.11-5.4 8.94-5.51 8.89z"
					className="prefix__animable"
					style={{
						transformOrigin: '334.83px 312px',
					}}
				/>
				<g
					id="prefix__el7036d16coht"
					className="prefix__animable"
					opacity={0.4}
					style={{
						transformOrigin: '354.883px 253.546px',
					}}
				>
					<path
						id="prefix__elojuryzv8gt"
						d="M358.8 205.11c-.65 0-8.65 9.4-12.19 15.74-11.91 21.33-12.45 42.08-15.75 66.28-.47 3.46-2.19 10.91-2.16 10.74 1.76 1.38 7.68 4.13 7.68 4.13s5.59-6.74 7.81-10.13c8.88-13.57 18.53-24.16 26.14-38.49s14.93-27 7.89-41.66c0 0-12.22-7.02-19.42-6.61z"
						className="prefix__animable"
						style={{
							transformOrigin: '354.883px 253.546px',
						}}
					/>
				</g>
				<path
					id="prefix__elk7mcspice4c"
					fill="#263238"
					d="M395 260.9a2.68 2.68 0 01-.21-.46c-.13-.3-.29-.77-.56-1.35a24.17 24.17 0 00-2.81-4.73 24.6 24.6 0 00-6.17-5.76 23.62 23.62 0 00-10.13-3.75H375l-.06-.08a57.13 57.13 0 00-6.49-7.46 32.74 32.74 0 00-8.87-6.1 22.79 22.79 0 00-10.49-2 17.31 17.31 0 00-9.17 3.06l-.15.1-.13-.12a18 18 0 00-9.8-4.35 17.67 17.67 0 00-8.33 1.06 18.7 18.7 0 00-4.78 2.69c-.5.4-.88.72-1.12.94a2.52 2.52 0 01-.39.32 4.37 4.37 0 01.34-.37c.23-.24.59-.58 1.08-1a17.55 17.55 0 014.78-2.83 17.77 17.77 0 018.47-1.18 18.29 18.29 0 0110.05 4.39h-.28a17.71 17.71 0 019.41-3.17 23.32 23.32 0 0110.71 2 33 33 0 019 6.21 55.8 55.8 0 016.52 7.55l-.15-.09a23.63 23.63 0 0110.28 3.88 24.69 24.69 0 016.19 5.91 22.74 22.74 0 012.72 4.84c.25.59.39 1.07.5 1.38a3.87 3.87 0 01.16.47z"
					className="prefix__animable"
					style={{
						transformOrigin: '355.11px 244.166px',
					}}
				/>
				<path
					id="prefix__elmvtjpdofkl"
					fill="#263238"
					d="M365.06 282.15a10.38 10.38 0 00-1.39-3.49 9.5 9.5 0 00-3.09-3 8.65 8.65 0 00-5.33-1.26h-.2v-.2a14.23 14.23 0 00-8.61-10.86 14.36 14.36 0 00-6.25-.95 14.09 14.09 0 00-5.59 1.61l-.15.08-.12-.13a10.2 10.2 0 00-5.59-3 11.27 11.27 0 00-5 .16 11.73 11.73 0 00-4 2s.06-.09.21-.22.37-.34.68-.57a10.09 10.09 0 013-1.45 11.3 11.3 0 015.15-.26 10.44 10.44 0 015.85 3.09l-.27-.05a14.55 14.55 0 015.74-1.66 14.74 14.74 0 016.46 1 14.2 14.2 0 016.17 4.82 14.37 14.37 0 012.68 6.43l-.23-.18a9 9 0 015.55 1.39 9.64 9.64 0 013.12 3.13 8.38 8.38 0 011.06 2.64 6.65 6.65 0 01.11.74 1.17 1.17 0 01.04.19z"
					className="prefix__animable"
					style={{
						transformOrigin: '342.4px 271.289px',
					}}
				/>
				<path
					id="prefix__eldi4jg03mudk"
					fill="#263238"
					d="M327.63 325.36s-.16-.2-.53-.36a2.63 2.63 0 00-1.7.06 5.14 5.14 0 01-1.29.23 2 2 0 01-1.44-.63c-.77-.81-1.06-2-1.79-2.77a1.68 1.68 0 00-1.34-.57 8.37 8.37 0 00-1.52.31 3.41 3.41 0 01-1.52.08 2.91 2.91 0 01-2.15-2.23 1.16 1.16 0 010-.68s0 .24.17.64a2.94 2.94 0 001 1.39 2.3 2.3 0 001.05.47 3 3 0 001.33-.12 8 8 0 011.61-.34 2.14 2.14 0 011.72.71c.85 1 1.11 2.1 1.76 2.79a1.63 1.63 0 001.12.54 4.62 4.62 0 001.19-.17 2.62 2.62 0 011.87.12c.42.24.48.53.46.53z"
					className="prefix__animable"
					style={{
						transformOrigin: '320.966px 322.08px',
					}}
				/>
				<path
					id="prefix__ellxk3l83knyc"
					fill="#263238"
					d="M329.3 323a61.46 61.46 0 01-7.09-3.5 64.91 64.91 0 01-6.87-3.94 64.09 64.09 0 017.1 3.5 65.34 65.34 0 016.86 3.94z"
					className="prefix__animable"
					style={{
						transformOrigin: '322.32px 319.28px',
					}}
				/>
				<path
					id="prefix__elm0cvair2g0f"
					fill="#263238"
					d="M326.41 326.69s-.2.1-.55.26c-.18.08-.38.2-.66.32a2.46 2.46 0 01-1 .13 4.16 4.16 0 01-2.4-.93 3.29 3.29 0 01-.91-1.25 4.55 4.55 0 00-.71-1.31 3.37 3.37 0 00-2.63-.76 6.06 6.06 0 01-2.53-.24 2.36 2.36 0 01-1.28-1.17c-.16-.39-.1-.64-.08-.63s.06.22.26.54a2.28 2.28 0 001.23.93 6 6 0 002.38.11 3.74 3.74 0 013 .88 5 5 0 01.78 1.45 3 3 0 00.77 1.11 4.06 4.06 0 002.13.92 3 3 0 001.56-.28c.41-.11.64-.11.64-.08z"
					className="prefix__animable"
					style={{
						transformOrigin: '320.021px 324.259px',
					}}
				/>
			</g>
			<g
				id="prefix__Character--inject-2"
				className="prefix__animable"
				style={{
					transformOrigin: '248.451px 263.475px',
					animation: '1s 1 forwards cubic-bezier(.36,-.01,.5,1.38) slideLeft',
					animationDelay: '0s',
				}}
			>
				<path
					id="prefix__elr3z8ddujws"
					fill={theme.colors.accent}
					d="M216.62 233.3l25.15-3.73-12-17-6.44.38a11.49 11.49 0 00-9.89 6.92 11.53 11.53 0 00-.73 6.74z"
					className="prefix__animable"
					style={{
						transformOrigin: '227.136px 222.935px',
					}}
				/>
				<path
					id="prefix__eluyk2vpudk48"
					d="M216.62 233.3l25.15-3.73-12-17-6.44.38a11.49 11.49 0 00-9.89 6.92 11.53 11.53 0 00-.73 6.74z"
					className="prefix__animable"
					opacity={0.4}
					style={{
						transformOrigin: '227.136px 222.935px',
					}}
				/>
				<path
					id="prefix__elzm1jdhn25d9"
					fill="#ffbe9d"
					d="M324.64 261.16c.26.72.54 1.57.83 2.59a8.2 8.2 0 01.27 2.7 12.52 12.52 0 01-.57 3.32c-.41 1 .55 1.92 1.36 1.44s2-3.08 2-6.42 3.34.66 4.61 2.1 5.41 5.71 6.16 5.67c1.1-.06 1.24-1.25-.06-2.66s-4.91-5.9-4.12-6.54 7.15 6.79 7.15 6.79 1.1 1.81 2 1.11c1.7-1.27-5.65-8.66-6.37-9.34-.49-.48.34-1.2.85-.66s6.18 7.55 7.92 6.27c1.33-1-6-6.13-6.36-8.23s5.16 4.47 6.39 3.49c.47-.37.76-.71-3-4-1.8-1.62-8.66-6.42-8.66-6.42l-20.64-59.09-15.94 9.13z"
					className="prefix__animable"
					style={{
						transformOrigin: '322.715px 232.92px',
					}}
				/>
				<path
					id="prefix__el01276dacfz94"
					fill="#ffbe9d"
					d="M201.77 149.4l-23.06-27.52a16.06 16.06 0 00-1.13-2.75 8.65 8.65 0 00-5.44-4.54c-1.46-.32-3.14-.16-4.29-1.12-.51-.43-.85-1-1.39-1.44s-1.43-.48-1.78.09a1.57 1.57 0 00.1 1.38 12.83 12.83 0 004 5.16c-2.16.66-5.25 1.66-5.25 1.66s-4.87-5.29-7.37-5.28 2 4.73 2 4.73-4-3.7-5.24-2.66 1.94 3.79 1.94 3.79a5.3 5.3 0 00-3.25-.91c-1.13.19-2.1 1.52-1.49 2.49a2.6 2.6 0 001 .81l6.14 3.42a7.43 7.43 0 003.28 1.2c3.12.1 5.49-1.83 8.6-1.75 0 0 17.39 36.61 19.15 38.5s40 4.46 40 4.46l22.11-19.05z"
					className="prefix__animable"
					style={{
						transformOrigin: '200.166px 140.408px',
					}}
				/>
				<path
					id="prefix__elw6vmsasvkyj"
					fill={theme.colors.accent}
					d="M271.05 348.83l23.55 10.25-12.6 29.37c-2.74-1.37-1.84-19-1.84-19l-15.36-5.95z"
					className="prefix__animable"
					style={{
						transformOrigin: '279.7px 368.64px',
					}}
				/>
				<g
					id="prefix__elma3vk50khgm"
					className="prefix__animable"
					opacity={0.6}
					style={{
						transformOrigin: '283.752px 360.579px',
					}}
				>
					<path
						id="prefix__elqnsbawa4hfn"
						fill="#fff"
						d="M282.77 359.84a1.31 1.31 0 011.73-.25 1.26 1.26 0 01.25 1.71 1.37 1.37 0 01-1.82.25 1.3 1.3 0 01-.07-1.81"
						className="prefix__animable"
						style={{
							transformOrigin: '283.752px 360.579px',
						}}
					/>
				</g>
				<path
					id="prefix__elv5tflaghwzg"
					fill="#fff"
					d="M294.6 359.08l-2.42-.94L281 386.65s0 1.56 1 1.8z"
					className="prefix__animable"
					opacity={0.6}
					style={{
						transformOrigin: '287.8px 373.295px',
					}}
				/>
				<path
					id="prefix__el15o06qc6v37"
					fill="#263238"
					d="M279.79 369.88c.15.05.52-.64 1.29-1.14s1.52-.63 1.52-.78-.92-.3-1.83.33-1.12 1.56-.98 1.59z"
					className="prefix__animable"
					style={{
						transformOrigin: '281.175px 368.855px',
					}}
				/>
				<path
					id="prefix__elcdd6ygb8vy9"
					fill="#263238"
					d="M279.74 373.5c.13.1.64-.42 1.46-.65s1.51-.09 1.57-.23-.72-.59-1.73-.29-1.43 1.11-1.3 1.17z"
					className="prefix__animable"
					style={{
						transformOrigin: '281.245px 372.871px',
					}}
				/>
				<path
					id="prefix__elkp4gn7jjegp"
					fill="#263238"
					d="M282.68 376.84c.09-.12-.55-.63-1.49-.6s-1.54.57-1.45.68.69-.12 1.47-.13 1.39.21 1.47.05z"
					className="prefix__animable"
					style={{
						transformOrigin: '281.21px 376.594px',
					}}
				/>
				<path
					id="prefix__elkkyzd7ofty"
					fill="#263238"
					d="M276.44 368.13c.16 0 .24-.75.65-1.53s.93-1.31.84-1.44-.87.26-1.33 1.19-.3 1.8-.16 1.78z"
					className="prefix__animable"
					style={{
						transformOrigin: '277.118px 366.633px',
					}}
				/>
				<path
					id="prefix__el9tpl2z3r03p"
					fill="#263238"
					d="M273.26 365.93c.06 0 .39-.5.62-1.47a6.86 6.86 0 00.19-1.74 7.63 7.63 0 00-.08-1 1.94 1.94 0 00-.16-.59.67.67 0 00-.73-.39.83.83 0 00-.6.48 2.18 2.18 0 00-.16.59 5.61 5.61 0 00.23 2.79c.33.95.77 1.41.82 1.37s-.23-.57-.46-1.48a6.64 6.64 0 01-.17-1.63 6.52 6.52 0 01.1-1c.05-.34.16-.61.34-.63s.08 0 .15.1a2 2 0 01.11.44 8.23 8.23 0 01.11 1 8.54 8.54 0 01-.07 1.65 11.28 11.28 0 00-.24 1.51z"
					className="prefix__animable"
					style={{
						transformOrigin: '273.165px 363.351px',
					}}
				/>
				<path
					id="prefix__elutmhkvezbh9"
					fill="#263238"
					d="M273.54 365.84s-.34-.55-1.25-1a5.1 5.1 0 00-1.7-.49 4.58 4.58 0 00-1 0 1.35 1.35 0 00-1.15.62.76.76 0 00.15.79 2.28 2.28 0 00.48.38 3.59 3.59 0 001 .43 3.7 3.7 0 001.78 0c1-.25 1.44-.77 1.4-.8a13.5 13.5 0 01-1.47.42 3.66 3.66 0 01-1.58-.12 3.39 3.39 0 01-.84-.4c-.29-.18-.49-.4-.41-.5a.89.89 0 01.7-.31 5.17 5.17 0 011 0 5.77 5.77 0 011.59.34c.76.32 1.25.7 1.3.64z"
					className="prefix__animable"
					style={{
						transformOrigin: '270.964px 365.501px',
					}}
				/>
				<path
					id="prefix__el7lrrwtem9rd"
					fill={theme.colors.accent}
					d="M252.91 389.17l9.74 20.41L265.7 428a2.31 2.31 0 002.82 1.87 2.29 2.29 0 001.61-1.38c1.79-4.43 8.3-20.78 7.5-22.23-.92-1.68-8.85-22.21-8.85-22.21z"
					className="prefix__animable"
					style={{
						transformOrigin: '265.304px 406.992px',
					}}
				/>
				<path
					id="prefix__elgn73dund2ba"
					fill="#fff"
					d="M268.52 429.82l8.48-25 .4.87a3.49 3.49 0 01.17 2.4c-.62 2.13-2.45 8-7.38 20.32a2.3 2.3 0 01-1.67 1.41z"
					className="prefix__animable"
					opacity={0.6}
					style={{
						transformOrigin: '273.114px 417.32px',
					}}
				/>
				<g
					id="prefix__elqq5f796l6b"
					className="prefix__animable"
					opacity={0.6}
					style={{
						transformOrigin: '270.891px 400.766px',
					}}
				>
					<path
						id="prefix__elc07raymj5t"
						fill="#fff"
						d="M269.78 400.07a1.39 1.39 0 00.4 1.82 1.35 1.35 0 001.82-.38 1.49 1.49 0 00-.44-1.93 1.39 1.39 0 00-1.84.61"
						className="prefix__animable"
						style={{
							transformOrigin: '270.891px 400.766px',
						}}
					/>
				</g>
				<path
					id="prefix__elfap8oj7gd1d"
					fill="#263238"
					d="M269.18 418.69a5 5 0 00-5.11-.18s.15-.32.6-.6a3.58 3.58 0 012-.51 3.63 3.63 0 011.95.64c.44.32.6.61.56.65z"
					className="prefix__animable"
					style={{
						transformOrigin: '266.628px 418.043px',
					}}
				/>
				<path
					id="prefix__elwjhnz3yi26"
					fill="#263238"
					d="M269 422.11a17.25 17.25 0 00-2.22-.06 19.15 19.15 0 00-2.13.64c-.11-.08.69-1 2.06-1.14a2.56 2.56 0 012.29.56z"
					className="prefix__animable"
					style={{
						transformOrigin: '266.82px 422.084px',
					}}
				/>
				<path
					id="prefix__elxaz3983aqo8"
					fill="#263238"
					d="M270.32 413.92a22.54 22.54 0 00-3.42-.33 21.25 21.25 0 00-3.38.66 6.31 6.31 0 016.8-.33z"
					className="prefix__animable"
					style={{
						transformOrigin: '266.92px 413.666px',
					}}
				/>
				<path
					id="prefix__el5uuvudbjmuc"
					fill="#263238"
					d="M267.88 407.47c0 .15-1.35.18-2.86.8s-2.52 1.49-2.62 1.38a4.5 4.5 0 012.44-1.85 4.45 4.45 0 013.04-.33z"
					className="prefix__animable"
					style={{
						transformOrigin: '265.14px 408.5px',
					}}
				/>
				<path
					id="prefix__el4bq7lqj6iav"
					fill="#263238"
					d="M265.17 401.4a11.51 11.51 0 00-3 .76 11.08 11.08 0 00-2.42 1.91c-.05 0 .05-.39.41-.87a4.88 4.88 0 011.8-1.49 4.93 4.93 0 012.27-.52c.6.02.95.16.94.21z"
					className="prefix__animable"
					style={{
						transformOrigin: '262.454px 402.63px',
					}}
				/>
				<path
					id="prefix__el3teqhmmzkdy"
					fill="#263238"
					d="M261.69 401a2.33 2.33 0 01-.38-.77 6.05 6.05 0 01-.29-2.32 2.63 2.63 0 01.51-1.61.93.93 0 011-.25 1.56 1.56 0 01.75.79 3.27 3.27 0 01-1.38 3.94 3.81 3.81 0 01-4.22-.28 1.31 1.31 0 01-.5-1 .91.91 0 01.67-.83 3.29 3.29 0 011.68.1 6.46 6.46 0 012.13 1c.45.31.65.54.63.57a10 10 0 00-2.84-1.2 2.93 2.93 0 00-1.47-.05.49.49 0 00-.37.45.94.94 0 00.37.62 3.39 3.39 0 003.66.21 2.84 2.84 0 001.26-3.37 1.05 1.05 0 00-.5-.57.49.49 0 00-.57.12 2.32 2.32 0 00-.46 1.36 8.87 8.87 0 00.32 3.09z"
					className="prefix__animable"
					style={{
						transformOrigin: '260.332px 398.642px',
					}}
				/>
				<path
					id="prefix__el0r19491uxd6"
					fill="#263238"
					d="M285.73 351.32l-26.71-18 8.84-86.79-25.3-14.77-23.13-5.36s-9.66 20.1-14 33c-9.14 27.37-15.55 37.18-14.08 57 .46 6.28 23.64 22 38.28 30.71a47.57 47.57 0 00.78 5.19c5 22.62 20.28 52.7 20.28 52.7l23.2-10.79-7.48-25.65 7.8 4.55zm-57.41-56l3.12-13.91c-.66 8.85-1.26 18.08-1.69 26.93a27.64 27.64 0 01-1.43-12.99z"
					className="prefix__animable"
					style={{
						transformOrigin: '238.435px 315.7px',
					}}
				/>
				<path
					id="prefix__elijtpwhviaif"
					fill="#455a64"
					d="M250.73 405a2.21 2.21 0 01-.18-.37l-.49-1.09-1.79-4.23c-1.54-3.69-3.75-9-6.4-15.65s-5.79-14.53-9-23.43c-.79-2.23-1.56-4.52-2.29-6.89-.32-1.2-.73-2.38-.94-3.65-.06-.31-.13-.63-.18-.94l-.11-.95-.21-1.9q-.42-3.84-.67-7.82c-.08-1.32-.15-2.66-.21-4s-.1-2.71 0-4.08c.4-10.9 1.31-21.24 2.07-30.65s1.49-17.87 2.09-25 1-12.87 1.2-16.86c.13-2 .2-3.54.24-4.58l.07-1.2a1.51 1.51 0 010-.4 2.38 2.38 0 010 .4v1.2c0 1.05-.05 2.6-.14 4.59-.17 4-.52 9.76-1.05 16.88s-1.24 15.59-2 25-1.63 19.75-2 30.62c-.08 1.36 0 2.7 0 4.05s.12 2.67.21 4q.24 4 .65 7.79l.21 1.91.11.94c.05.31.12.61.18.92.2 1.22.6 2.41.92 3.6.71 2.35 1.48 4.64 2.26 6.87 3.14 8.89 6.25 16.8 8.85 23.45s4.77 12 6.26 15.7c.73 1.84 1.31 3.27 1.71 4.27.18.47.33.84.43 1.11s.2.39.2.39z"
					className="prefix__animable"
					style={{
						transformOrigin: '239.465px 328.155px',
					}}
				/>
				<path
					id="prefix__el4hbmbvks2dg"
					fill="#455a64"
					d="M232.87 248.37s-.23-.14-.6-.49a6.31 6.31 0 01-1.13-1.82 19.44 19.44 0 01-1.14-6.88 58.24 58.24 0 01.33-7 15.9 15.9 0 01.4-2.85 16.91 16.91 0 010 2.88c-.09 1.77-.23 4.22-.19 6.92a22.37 22.37 0 001 6.77 7.73 7.73 0 00.95 1.81c.24.41.41.64.38.66z"
					className="prefix__animable"
					style={{
						transformOrigin: '231.434px 238.85px',
					}}
				/>
				<path
					id="prefix__elmzg25fk6pdc"
					fill="#455a64"
					d="M222.89 227.72a5.82 5.82 0 01.2 2.42 11.2 11.2 0 01-6.39 9.38 5.91 5.91 0 01-2.33.7c0-.08.87-.32 2.17-1a11.84 11.84 0 006.2-9.09c.17-1.48.06-2.41.15-2.41z"
					className="prefix__animable"
					style={{
						transformOrigin: '218.754px 233.97px',
					}}
				/>
				<path
					id="prefix__eln4dpj83803"
					fill="#455a64"
					d="M264.39 278.18v.26c0 .17 0 .46-.06.77q-.12 1.08-.3 3c-.29 2.61-.69 6.32-1.19 10.88-1 9.23-2.48 21.9-4.06 35.91a65.37 65.37 0 001.31 19.7 68 68 0 002.41 8.3c.9 2.56 1.73 4.95 2.5 7.14 1.51 4.34 2.73 7.87 3.59 10.35.4 1.2.72 2.14 1 2.82l.24.74a.8.8 0 01.06.25s0-.07-.11-.24-.17-.42-.28-.72c-.26-.67-.6-1.6-1-2.79l-3.74-10.29c-.78-2.18-1.64-4.57-2.56-7.12a67.56 67.56 0 01-2.47-8.35 64.94 64.94 0 01-1.37-19.84c1.56-14.05 3.12-26.71 4.21-35.9.57-4.56 1-8.27 1.35-10.87.16-1.26.29-2.24.39-3 0-.31.08-.56.11-.76a.85.85 0 01-.03-.24z"
					className="prefix__animable"
					style={{
						transformOrigin: '264.008px 328.24px',
					}}
				/>
				<path
					id="prefix__el3mgzc6ggeyd"
					fill="#ffbe9d"
					d="M219.43 226.25l4.1-10.47 9.35 13.94-12.47-3.11"
					className="prefix__animable"
					style={{
						transformOrigin: '226.155px 222.75px',
					}}
				/>
				<path
					id="prefix__elxx9utw75nbg"
					fill={theme.colors.accent}
					d="M317.25 194.78l-11.09-24.87c-1.33-4-3.82-4.62-5.9-6.94l-27.79-15.67c-20.26-8.3-31.29 19.52-31.29 19.52l-18.41 42c-21.65 7.17-6.32 23.8-6.32 23.8s-5.89-10.24-.09-13.93c6.06-3.87 12.78 6.32 19.24 16 7.63 11.42 32.41 18.84 32.41 18.84 2.9-3.06 2.09-13.75 4.36-19.49.72-1.81 17.88-41.56 17.88-41.56l6.65 16.11s8.52-10 12.66-11.58a47.13 47.13 0 017.69-2.23z"
					className="prefix__animable"
					style={{
						transformOrigin: '264.217px 199.642px',
					}}
				/>
				<path
					id="prefix__elsaok9h4ik1a"
					fill="#263238"
					d="M268 212.46a4.15 4.15 0 01-.84.59 16.25 16.25 0 01-2.53 1.19 18.09 18.09 0 01-17.56-2.71 15.57 15.57 0 01-2.05-1.9 3.26 3.26 0 01-.62-.81c.07-.07 1 1.06 2.88 2.43a18.79 18.79 0 0017.23 2.66c2.22-.74 3.49-1.53 3.49-1.45z"
					className="prefix__animable"
					style={{
						transformOrigin: '256.2px 212.109px',
					}}
				/>
				<path
					id="prefix__elh7hnz8tdlol"
					fill={theme.colors.accent}
					d="M222 173l17.15 1.62 27.44-29-16.14-.56-28.73 2.84A74.54 74.54 0 01222 173z"
					className="prefix__animable"
					style={{
						transformOrigin: '244.155px 159.84px',
					}}
				/>
				<path
					id="prefix__elpdyhd4613t9"
					fill="#263238"
					d="M245.77 157.74a1.81 1.81 0 01-.21.53l-.69 1.48c-.62 1.34-1.49 3.19-2.54 5.43-2.13 4.6-5 11-8 18.1s-5.86 13.53-8 18.14c-1.05 2.31-1.92 4.16-2.54 5.44l-.73 1.46a2.44 2.44 0 01-.29.5 3.31 3.31 0 01.2-.54l.64-1.5 2.4-5.5c2-4.71 4.79-11.12 7.83-18.2s6-13.48 8.17-18.05c1.11-2.28 2-4.11 2.69-5.37.32-.58.57-1.06.77-1.44a2.65 2.65 0 01.3-.48z"
					className="prefix__animable"
					style={{
						transformOrigin: '234.27px 183.28px',
					}}
				/>
				<path
					id="prefix__elwtdzasitqun"
					fill="#263238"
					d="M298.12 166.17c.05.09-1 .64-2.47 1.88a17.74 17.74 0 00-2.31 2.39 19.58 19.58 0 00-2.18 3.45 20.39 20.39 0 00-1.36 3.84 17.47 17.47 0 00-.44 3.31c-.07 1.9.15 3.08.05 3.09a2.9 2.9 0 01-.2-.81 5.9 5.9 0 01-.14-1A10.4 10.4 0 01289 181a15.68 15.68 0 01.35-3.4 19 19 0 011.35-4 19.21 19.21 0 012.27-3.52 16.18 16.18 0 012.45-2.37 9.43 9.43 0 011.07-.76 6.12 6.12 0 01.85-.51 2.62 2.62 0 01.78-.27z"
					className="prefix__animable"
					style={{
						transformOrigin: '293.561px 175.15px',
					}}
				/>
				<path
					id="prefix__el7xaqphorhaq"
					fill="#ffbe9d"
					d="M302.91 126.34l-16.09 30.08a10.62 10.62 0 01-13.69 4.68 10.61 10.61 0 01-5-14.64l2.77-5.22s-7-4.53-3.72-12.09c2.92-6.74 13.64-25.78 15.91-29.8l13.56 6.07a15.23 15.23 0 016.26 20.92z"
					className="prefix__animable"
					style={{
						transformOrigin: '285.571px 130.686px',
					}}
				/>
				<path
					id="prefix__el3rydaddyhvl"
					fill="#eb996e"
					d="M270.9 141.13a19.19 19.19 0 0011.05 1.6s-4.74 4.37-11.94.34z"
					className="prefix__animable"
					style={{
						transformOrigin: '275.98px 142.954px',
					}}
				/>
				<path
					id="prefix__ell9fklh09fc9"
					fill="#263238"
					d="M276.07 117.08a1.18 1.18 0 00.55 1.55 1.13 1.13 0 001.54-.47 1.18 1.18 0 00-.55-1.55 1.13 1.13 0 00-1.54.47z"
					className="prefix__animable"
					style={{
						transformOrigin: '277.115px 117.62px',
					}}
				/>
				<path
					id="prefix__elc59kc2l5zd"
					fill="#263238"
					d="M276.13 116.13c.07.2 1.15 0 2.31.48s1.74 1.47 1.93 1.39.06-.35-.18-.78a3.28 3.28 0 00-1.47-1.29 3.14 3.14 0 00-1.92-.21c-.47.11-.71.28-.67.41z"
					className="prefix__animable"
					style={{
						transformOrigin: '278.293px 116.83px',
					}}
				/>
				<path
					id="prefix__eluixkykrvmef"
					fill="#263238"
					d="M286.88 122.4a1.17 1.17 0 00.55 1.55 1.13 1.13 0 001.54-.48 1.18 1.18 0 00-.55-1.54 1.12 1.12 0 00-1.54.47z"
					className="prefix__animable"
					style={{
						transformOrigin: '287.922px 122.937px',
					}}
				/>
				<path
					id="prefix__el4qxbkzsvwly"
					fill="#263238"
					d="M287.39 121.42c.07.2 1.15 0 2.31.48s1.74 1.48 1.93 1.4.07-.35-.18-.79a3.27 3.27 0 00-1.47-1.28 3.07 3.07 0 00-1.92-.21c-.47.11-.71.31-.67.4z"
					className="prefix__animable"
					style={{
						transformOrigin: '289.555px 122.128px',
					}}
				/>
				<path
					id="prefix__elreqz7dsa6j"
					fill="#263238"
					d="M279.36 128.11a8.7 8.7 0 00-1.72-1.17c-.28-.17-.54-.35-.5-.57s.3-.51.59-.79l1.88-1.86a42.75 42.75 0 004.59-5 41.62 41.62 0 00-5 4.61l-1.85 1.89a1.87 1.87 0 00-.67 1.09.85.85 0 00.29.65 2.31 2.31 0 00.47.29 7.66 7.66 0 001.92.86z"
					className="prefix__animable"
					style={{
						transformOrigin: '280.44px 123.415px',
					}}
				/>
				<path
					id="prefix__eloic1sl8ryw"
					fill="#263238"
					d="M282.4 130.43c-.18-.09-.74 1.16-2.21 1.57s-2.75-.32-2.84-.15.19.37.75.64a3.39 3.39 0 002.33.2 2.94 2.94 0 001.78-1.35c.28-.5.28-.87.19-.91z"
					className="prefix__animable"
					style={{
						transformOrigin: '279.893px 131.618px',
					}}
				/>
				<path
					id="prefix__el8wgl3bd1err"
					fill="#263238"
					d="M289.12 116.76c0 .37 1.19.73 2.47 1.5s2.22 1.63 2.52 1.43c.14-.1.08-.48-.22-1a5.08 5.08 0 00-3.81-2.23c-.58 0-.95.13-.96.3z"
					className="prefix__animable"
					style={{
						transformOrigin: '291.651px 118.09px',
					}}
				/>
				<path
					id="prefix__elmpg2bln2ho"
					fill="#263238"
					d="M278.05 113c.09.36 1 .44 2 .85s1.69 1 2 .78.14-.42-.11-.82a2.87 2.87 0 00-1.39-1.14 2.93 2.93 0 00-1.8-.17c-.5.08-.75.3-.7.5z"
					className="prefix__animable"
					style={{
						transformOrigin: '280.131px 113.554px',
					}}
				/>
				<path
					id="prefix__el005vrg5o2hfnv"
					fill="#ffbe9d"
					d="M297.85 130.85c.16 0 5.85.78 3.39 5.85s-7 1.55-7 1.4 3.61-7.25 3.61-7.25z"
					className="prefix__animable"
					style={{
						transformOrigin: '298.048px 135.139px',
					}}
				/>
				<path
					id="prefix__elyjmublihk8n"
					fill="#eb996e"
					d="M296.74 136.93s.06.1.17.25a1 1 0 00.66.33 2.44 2.44 0 002-1.47 3.28 3.28 0 00.37-1.38 1.14 1.14 0 00-.34-1 .5.5 0 00-.64 0c-.13.11-.15.22-.18.22s-.07-.14.07-.33a.64.64 0 01.36-.22.75.75 0 01.55.11 1.39 1.39 0 01.54 1.23 3.35 3.35 0 01-.38 1.56 2.56 2.56 0 01-2.35 1.57 1.13 1.13 0 01-.75-.53c-.13-.21-.1-.34-.08-.34z"
					className="prefix__animable"
					style={{
						transformOrigin: '298.514px 135.559px',
					}}
				/>
				<path
					id="prefix__elnrr6ycrsf3m"
					fill="#263238"
					d="M300.79 132a3 3 0 00-3 .17c-.66.58-1.11 1.38-1.77 2a2 2 0 01-2.42.38 1.73 1.73 0 01-.54-1.65 4.78 4.78 0 01.73-1.67c1.37-2.26 2.94-4.42 4.12-6.79a13.49 13.49 0 001.61-7.67 7.4 7.4 0 00-4.5-6.07c-1.65-.58-3.49-.41-5.18-.89a6.91 6.91 0 01-4.44-4.08 12 12 0 00-.66-1.14c.06-.12.66-1.14 1.25-1.34 4.46-1.51 10.49.22 14.18 3.13a16.39 16.39 0 016.32 12.28c.05 4.7-2.91 9.88-5.66 13.38"
					className="prefix__animable"
					style={{
						transformOrigin: '295.615px 118.745px',
					}}
				/>
				<path
					id="prefix__elkg2wwswl74k"
					fill="#263238"
					d="M300.84 131.45c1.41-2.4 3.69-4.13 5.43-6.29s3-5.21 1.7-7.69c-.42-.85-1.1-1.56-1.45-2.44a12.09 12.09 0 01-.5-2.81 9.76 9.76 0 00-4.84-7c-1.78-1-3.83-1.4-5.64-2.35-2.85-1.5-4.94-4.28-8-5.47a3.8 3.8 0 00-3.11-.1 1.88 1.88 0 00-.77 2.69 1.63 1.63 0 00-2.25-1.08 2.46 2.46 0 00-1.27 2.41 5.35 5.35 0 001.09 2.64 13.54 13.54 0 007.7 5.31l6.91-.54 5.66 4.19 4.18 6.67-7.59 9.86z"
					className="prefix__animable"
					style={{
						transformOrigin: '294.317px 114.233px',
					}}
				/>
			</g>
			<g
				id="prefix__light-bulb--inject-2"
				className="prefix__animable"
				style={{
					transformOrigin: '233.321px 102.601px',
					animation: '1s 1 forwards cubic-bezier(.36,-.01,.5,1.38) zoomOut',
					animationDelay: '0s',
				}}
			>
				<path
					id="prefix__elju56tr960w"
					fill={theme.colors.accent}
					d="M257.65 92.1a24.38 24.38 0 10-33.27 24.25c.19 2.88.4 6.14.4 6.14l21.32-1.38s-.09-3.45-.25-6.53a24.36 24.36 0 0011.8-22.48z"
					className="prefix__animable"
					style={{
						transformOrigin: '233.321px 95.8892px',
					}}
				/>
				<path
					id="prefix__elr1bhi06k45l"
					fill="#263238"
					d="M238.38 121.51v-1.19c0-.77.11-1.92.23-3.39s.29-3.28.62-5.35a36.91 36.91 0 011.77-6.92 23.64 23.64 0 011.77-3.77 4.53 4.53 0 011.57-1.6 2.51 2.51 0 012.31-.07 1.65 1.65 0 01.83.91 1.67 1.67 0 010 1.26 3.49 3.49 0 01-1.77 1.74 6.7 6.7 0 01-8.71-3.87 5.12 5.12 0 01-.33-2.8 2.19 2.19 0 012.11-1.83 1.54 1.54 0 011.29.78 2.19 2.19 0 01.29 1.48 4.78 4.78 0 01-1.55 2.53 7.24 7.24 0 01-5.57 2 6.34 6.34 0 01-4.93-3.2 3.69 3.69 0 01-.35-2.94 1.78 1.78 0 012.5-1.1 2.48 2.48 0 011.1 1 3.88 3.88 0 01.46 1.38 6.93 6.93 0 01-.19 2.8 7.24 7.24 0 01-3.17 4.3 7.38 7.38 0 01-5 1 3.89 3.89 0 01-2.26-1 1.88 1.88 0 01-.29-2.33 1.61 1.61 0 011.06-.61 2.37 2.37 0 011.18.15 3.48 3.48 0 011.59 1.58c.7 1.28 1.27 2.53 1.85 3.72 2.26 4.78 3.9 8.73 5 11.47.55 1.37 1 2.44 1.24 3.16.13.36.23.64.31.83a2.19 2.19 0 01.09.29l-.12-.28-.34-.81-1.29-3.15c-1.14-2.72-2.81-6.66-5.09-11.42-.58-1.18-1.17-2.45-1.86-3.7a3.29 3.29 0 00-1.48-1.47 1.68 1.68 0 00-1.95.38 1.65 1.65 0 00.28 2 3.62 3.62 0 002.11.93 7.11 7.11 0 004.81-1 7 7 0 003-4.14 6.6 6.6 0 00.18-2.69 2.63 2.63 0 00-1.39-2.15 1.52 1.52 0 00-1.27 0 1.55 1.55 0 00-.83 1 3.39 3.39 0 00.33 2.7 6.07 6.07 0 004.7 3 6.93 6.93 0 005.33-1.93 4.51 4.51 0 001.45-2.34 1.93 1.93 0 00-.25-1.29 1.28 1.28 0 00-1.05-.64 1.77 1.77 0 00-1.19.47 2 2 0 00-.62 1.13 4.79 4.79 0 00.31 2.64 6.43 6.43 0 008.4 3.73 3.24 3.24 0 001.64-1.6 1.44 1.44 0 00-.68-1.85 2.27 2.27 0 00-2.09.06A4.34 4.34 0 00243 101a23.63 23.63 0 00-1.76 3.72 36.46 36.46 0 00-1.8 6.86c-.35 2.07-.54 3.87-.68 5.34s-.21 2.6-.28 3.39c0 .37-.06.67-.07.88s-.03.32-.03.32z"
					className="prefix__animable"
					style={{
						transformOrigin: '234.218px 107.957px',
					}}
				/>
				<path
					id="prefix__eln0432ab2whc"
					fill="#263238"
					d="M244.71 125.16l-18.3 1.19a1.39 1.39 0 01-1.47-1.29 1.38 1.38 0 011.29-1.46l18.31-1.18a1.37 1.37 0 011.46 1.28 1.38 1.38 0 01-1.29 1.46z"
					className="prefix__animable"
					style={{
						transformOrigin: '235.47px 124.385px',
					}}
				/>
				<path
					id="prefix__el84g9jzxn6cl"
					fill="#263238"
					d="M244.79 126.36l-18.31 1.19a1.37 1.37 0 00.18 2.74l2-.13 3 4.91a1.79 1.79 0 001.63.84l6.54-.42a1.81 1.81 0 001.51-1.06l2.26-5.23 1.37-.09a1.38 1.38 0 00-.18-2.75z"
					className="prefix__animable"
					style={{
						transformOrigin: '235.692px 131.137px',
					}}
				/>
				<g
					id="prefix__elqsy5bklc7h"
					className="prefix__animable"
					opacity={0.2}
					style={{
						transformOrigin: '219.092px 88.4216px',
					}}
				>
					<path
						id="prefix__elswe10x7njqk"
						fill="#fff"
						d="M216.69 79.18a17.72 17.72 0 014.67-3.55 13.17 13.17 0 013.64-1.34c.92-.15 1.45-.1 1.51.09.13.44-1.67 1.21-4 3a20.06 20.06 0 00-7.85 20.24c.53 2.95 1.34 4.73 1 5-.18.11-.6-.21-1.17-.94a12.65 12.65 0 01-1.82-3.47 17.84 17.84 0 01-1-5.76 19 19 0 011.25-7.15"
						className="prefix__animable"
						style={{
							transformOrigin: '219.092px 88.4216px',
						}}
					/>
				</g>
				<g
					id="prefix__ele9t49ld3yye"
					className="prefix__animable"
					opacity={0.2}
					style={{
						transformOrigin: '232.373px 73.5666px',
					}}
				>
					<path
						id="prefix__elkbh2uzyxszm"
						fill="#fff"
						d="M232.45 75.06c-1.54.08-2.93-.25-3.14-1.06-.09-.4.17-.85.7-1.22a4.94 4.94 0 014.6-.28c.58.31.88.73.84 1.14"
						className="prefix__animable"
						style={{
							transformOrigin: '232.373px 73.5666px',
						}}
					/>
				</g>
				<path
					id="prefix__elg22r3gxybv"
					fill="#455a64"
					d="M241 134.88a10.67 10.67 0 01-1.29-.31 10.66 10.66 0 00-3.13-.19 10.8 10.8 0 00-3 .77c-.74.32-1.18.58-1.2.54a3.71 3.71 0 011.11-.73 9 9 0 013.1-.87 8.84 8.84 0 013.21.28 3.87 3.87 0 011.2.51z"
					className="prefix__animable"
					style={{
						transformOrigin: '236.69px 134.872px',
					}}
				/>
				<path
					id="prefix__el2zrkptzjxd"
					fill="#455a64"
					d="M243.65 129.26c0 .08-3.3.36-7.39.63s-7.39.41-7.4.32 3.3-.36 7.38-.62 7.4-.41 7.41-.33z"
					className="prefix__animable"
					style={{
						transformOrigin: '236.255px 129.738px',
					}}
				/>
				<path
					id="prefix__el5u33y7fyfi6"
					fill="#455a64"
					d="M242.64 131.51a11 11 0 01-1.79.29c-1.11.15-2.65.31-4.36.45s-3.25.21-4.37.24a10 10 0 01-1.81 0 10.08 10.08 0 011.8-.2l4.36-.33 4.36-.36a10.67 10.67 0 011.81-.09z"
					className="prefix__animable"
					style={{
						transformOrigin: '236.475px 132.017px',
					}}
				/>
			</g>
			<g
				id="prefix__Book--inject-2"
				className="prefix__animable"
				style={{
					transformOrigin: '133.311px 222.475px',
					animation: '1s 1 forwards cubic-bezier(.36,-.01,.5,1.38) slideRight',
					animationDelay: '0s',
				}}
			>
				<path
					id="prefix__eltv4xxum9e6j"
					fill={theme.colors.accent}
					d="M110.8 254l4 6.37s17-15.61 33.79-11.2c-.03-.02-15.15-12.84-37.79 4.83z"
					className="prefix__animable"
					style={{
						transformOrigin: '129.695px 252.514px',
					}}
				/>
				<path
					id="prefix__elhpjqf82ezg"
					fill={theme.colors.accent}
					d="M148.56 249.15s12.45-13.52 30.71-10.44l5 .75-1.58-2.87s-31.79-10.88-34.13 12.56z"
					className="prefix__animable"
					style={{
						transformOrigin: '166.415px 241.609px',
					}}
				/>
				<path
					id="prefix__elv5pepx21m1"
					fill="#f5f5f5"
					d="M122.28 203.56s-24.94-4.29-41.28 4.56L107 257s21.26-20.49 41.6-7.87z"
					className="prefix__animable"
					style={{
						transformOrigin: '114.8px 229.76px',
					}}
				/>
				<path
					id="prefix__elpcr5m4ni0f"
					fill="#263238"
					d="M122.28 203.56a1.72 1.72 0 01.14.2l.37.62 1.44 2.43 5.4 9.26 19.12 33a.22.22 0 01-.3.3 27.47 27.47 0 00-9.57-3.67 30 30 0 00-5.39-.38 33.89 33.89 0 00-5.52.7 47.4 47.4 0 00-20.62 11l-.22.2a.24.24 0 01-.35 0v-.05l-25.97-48.95a.19.19 0 01.08-.27A50.29 50.29 0 0197.51 203a84.53 84.53 0 0113.31-.58c3.68.13 6.55.42 8.49.66 1 .12 1.72.23 2.22.32l.57.1h.18-.19l-.57-.07c-.5-.06-1.24-.16-2.22-.26-2-.2-4.82-.45-8.49-.54a84.91 84.91 0 00-13.25.67 50.17 50.17 0 00-16.47 5l.08-.27 26 48.88-.39-.07.22-.2a52.65 52.65 0 019.94-7 41.5 41.5 0 0110.92-4.14 32.72 32.72 0 015.61-.7 29.24 29.24 0 015.48.39 28.13 28.13 0 019.72 3.75l-.31.3-18.91-33-5.32-9.34-1.39-2.48c-.15-.28-.27-.49-.35-.65a1.68 1.68 0 01-.11-.21z"
					className="prefix__animable"
					style={{
						transformOrigin: '114.781px 229.831px',
					}}
				/>
				<path
					id="prefix__elkgfwveqox"
					fill="#f5f5f5"
					d="M161.48 193.53s-18.86-12-38.88 10l26.28 45.59s5.36-18.5 36.74-12.07z"
					className="prefix__animable"
					style={{
						transformOrigin: '154.11px 219.692px',
					}}
				/>
				<path
					id="prefix__elde3fsdbti2c"
					fill="#263238"
					d="M161.48 193.53a.88.88 0 01.13.19c.08.15.2.34.34.59l1.33 2.32 5 8.84L185.81 237a.21.21 0 01-.08.29.21.21 0 01-.15 0l-4.67-.77a21.4 21.4 0 00-2.41-.27l-2.46-.2a21 21 0 00-2.51-.06L171 236c-.86 0-1.71.17-2.57.25a19.15 19.15 0 00-2.58.39l-2.57.58-2.53.89a26.77 26.77 0 00-2.44 1.13 7.63 7.63 0 00-1.18.67l-1.15.74c-.76.51-1.44 1.13-2.15 1.7a22.7 22.7 0 00-1.89 2 15.75 15.75 0 00-2.8 4.8.25.25 0 01-.32.15.24.24 0 01-.13-.11l-1.06-1.83c-9.29-16.15-18-31.2-25.19-43.77a.23.23 0 010-.25 44.24 44.24 0 0114.23-10.7 28.42 28.42 0 0113.43-2.52 27.58 27.58 0 018.59 1.92 21.27 21.27 0 012.1 1l.52.3.16.12-.18-.09-.53-.27c-.47-.23-1.17-.57-2.11-.93a28.07 28.07 0 00-21.84.79 44.08 44.08 0 00-14.06 10.67v-.24l25.25 43.8 1.1 1.81-.46.06a16.46 16.46 0 012.89-5 27.13 27.13 0 011.94-2.07c.74-.59 1.43-1.23 2.21-1.74l1.17-.76a8 8 0 011.21-.68c.84-.39 1.63-.86 2.49-1.16l2.57-.89 2.62-.59a18 18 0 012.62-.39c.87-.08 1.73-.24 2.59-.25h2.57a17.75 17.75 0 012.53.06l2.48.21a19.42 19.42 0 012.43.27l4.7.79-.23.32c-7.17-13-13.16-23.89-17.38-31.56-2.09-3.81-3.74-6.83-4.87-8.91l-1.28-2.36c-.13-.26-.24-.46-.32-.61s-.1-.21-.1-.21z"
					className="prefix__animable"
					style={{
						transformOrigin: '154.121px 219.694px',
					}}
				/>
				<path
					id="prefix__elfublt00s24"
					fill="#f5f5f5"
					d="M88 184.62l28.13 54.15s22.85-1 32.53 10.14l-26.06-45.35s-15.28-18.94-34.6-18.94z"
					className="prefix__animable"
					style={{
						transformOrigin: '118.33px 216.765px',
					}}
				/>
				<path
					id="prefix__elxdyyphbpijs"
					fill="#263238"
					d="M88 184.62h.72l2.16.09h.76l.83.14 1.88.31a15.16 15.16 0 012.15.52 24.54 24.54 0 012.41.71c6.72 2.24 15.74 7.66 23.84 17l26.1 45.32a.25.25 0 01-.09.34.24.24 0 01-.3 0l-1.1-1.1a23.85 23.85 0 00-7.2-4.75 41 41 0 00-8.05-2.64 67.2 67.2 0 00-16-1.58.24.24 0 01-.21-.12c-8.2-15.9-15.2-29.49-20.17-39.11-2.42-4.75-4.36-8.55-5.73-11.17-.65-1.27-1.16-2.26-1.51-3l-.38-.75-.12-.25a1.41 1.41 0 01.15.27l.42.77 1.58 3L96 199.84l20.3 38.82-.21-.12a67.19 67.19 0 0116.11 1.56 42.08 42.08 0 018.14 2.66 24.39 24.39 0 017.34 4.86l1.14 1.12-.41.29c-9.67-16.85-18.62-32.47-26-45.36a65.6 65.6 0 00-12.31-11.14 47.43 47.43 0 00-11.28-5.88 24.71 24.71 0 00-2.4-.73 14.79 14.79 0 00-2.13-.54l-1.87-.34-.81-.15-.74-.06z"
					className="prefix__animable"
					style={{
						transformOrigin: '118.437px 216.841px',
					}}
				/>
			</g>
			<g
				id="prefix__Stars--inject-2"
				className="prefix__animable"
				style={{
					transformOrigin: '248.8px 279.12px',
					animation: '1s 1 forwards cubic-bezier(.36,-.01,.5,1.38) zoomOut',
					animationDelay: '0s',
				}}
			>
				<path
					id="prefix__elef8qdytk8d"
					fill={theme.colors.accent}
					d="M175.8 338.2l.6 1a6.9 6.9 0 002.84 2.62l1 .52-1 .6a7 7 0 00-2.62 2.84l-.52 1.05-.6-1a6.9 6.9 0 00-2.84-2.62l-1.05-.52 1-.6a6.83 6.83 0 002.62-2.84z"
					className="prefix__animable"
					style={{
						transformOrigin: '175.925px 342.515px',
					}}
				/>
				<path
					id="prefix__elr6l8ovsp4y"
					fill={theme.colors.accent}
					d="M120.36 131.93l.6 1a6.88 6.88 0 002.84 2.61l1 .52-1 .61a6.78 6.78 0 00-2.62 2.84l-.52 1-.6-1a6.86 6.86 0 00-2.85-2.62l-1.05-.52 1-.6a7 7 0 002.62-2.85z"
					className="prefix__animable"
					style={{
						transformOrigin: '120.48px 136.22px',
					}}
				/>
				<path
					id="prefix__elt32nf3f75g"
					fill={theme.colors.accent}
					d="M376.94 167.52l.6 1a6.86 6.86 0 002.85 2.62l1.05.52-1 .6a6.9 6.9 0 00-2.61 2.85l-.52 1-.61-1a6.82 6.82 0 00-2.84-2.61l-1.06-.5 1-.61a6.78 6.78 0 002.62-2.84z"
					className="prefix__animable"
					style={{
						transformOrigin: '377.12px 171.815px',
					}}
				/>
				<path
					id="prefix__elem6678yqj3b"
					fill={theme.colors.accent}
					d="M346.64 351.15l.6 1a6.9 6.9 0 002.85 2.61l1.05.52-1 .61a6.91 6.91 0 00-2.62 2.84l-.51 1.05-.61-1a6.83 6.83 0 00-2.84-2.62l-1.05-.52 1-.6a6.86 6.86 0 002.62-2.85z"
					className="prefix__animable"
					style={{
						transformOrigin: '346.825px 355.465px',
					}}
				/>
				<path
					id="prefix__elkl1t9dvylmk"
					fill={theme.colors.accent}
					d="M352.41 389.74l.29.12a1.87 1.87 0 001 .1l.31-.06-.12.29a1.87 1.87 0 00-.1 1l.06.31-.29-.12a1.88 1.88 0 00-1-.1l-.31.06.12-.29a1.87 1.87 0 00.1-1z"
					className="prefix__animable"
					style={{
						transformOrigin: '353.13px 390.62px',
					}}
				/>
				<path
					id="prefix__elab6svlqx9a"
					fill={theme.colors.accent}
					d="M369.86 128.81l.28.12a1.9 1.9 0 001 .1l.3-.06-.11.29a1.78 1.78 0 00-.1 1l.06.31-.29-.12a1.9 1.9 0 00-1-.1l-.3.06.11-.29a1.78 1.78 0 00.1-1z"
					className="prefix__animable"
					style={{
						transformOrigin: '370.57px 129.69px',
					}}
				/>
				<path
					id="prefix__elwn2xyzu26k"
					fill={theme.colors.accent}
					d="M131.63 105l.29.11a1.78 1.78 0 001 .1h.31l-.12.28a1.9 1.9 0 00-.1 1l.06.3-.29-.11a1.78 1.78 0 00-1-.1h-.31l.12-.28a1.9 1.9 0 00.1-1z"
					className="prefix__animable"
					style={{
						transformOrigin: '132.35px 105.895px',
					}}
				/>
				<path
					id="prefix__el90liuljy13n"
					fill={theme.colors.accent}
					d="M161 308.16l.29.11a1.7 1.7 0 001 .1l.31-.05-.12.29a1.87 1.87 0 00-.1 1l.06.3-.29-.11a1.78 1.78 0 00-1-.1l-.31.06.12-.29a1.9 1.9 0 00.1-1z"
					className="prefix__animable"
					style={{
						transformOrigin: '161.72px 309.035px',
					}}
				/>
				<path
					id="prefix__elbhtwpbicwah"
					fill={theme.colors.accent}
					d="M192.91 386.69l-.28.29-.83.83-3.23 3.19-12 11.57v-.12C177.2 415 178 432 178.84 451v.42l-.36-.23-4.33-2.9-27.44-18.37h-.06v-.06c-4.1-12.23-7.55-22.55-10-29.83-1.2-3.62-2.14-6.48-2.8-8.46l-.72-2.25q-.12-.36-.18-.57c0-.14-.06-.2-.05-.2l.08.18.21.57c.18.53.44 1.27.78 2.23l2.9 8.43c2.48 7.27 6 17.56 10.14 29.77l-.08-.1 27.47 18.34 4.33 2.89-.34.19c-.81-19-1.54-36-2.08-48.57v-.07h.05l12.08-11.46 3.29-3.09.86-.79c.21-.3.32-.38.32-.38z"
					className="prefix__animable"
					style={{
						transformOrigin: '162.904px 419.055px',
					}}
				/>
				<path
					id="prefix__el7nlvalisns6"
					fill={theme.colors.accent}
					d="M180.13 445.25l-.09 2.83 2.29 1.68-2.72.79-.89 2.69-1.6-2.34-2.83-.01 1.74-2.24-.87-2.7 2.67.96 2.3-1.66z"
					className="prefix__animable"
					style={{
						transformOrigin: '178.31px 449.245px',
					}}
				/>
				<path
					id="prefix__el2r4akqydp81"
					fill={theme.colors.accent}
					d="M147.07 426.04l.06 1.83 1.54 1-1.73.61-.47 1.78-1.12-1.45-1.83.1 1.03-1.52-.66-1.71 1.76.52 1.42-1.16z"
					className="prefix__animable"
					style={{
						transformOrigin: '146.095px 428.65px',
					}}
				/>
				<path
					id="prefix__el7ejh0uzelrr"
					fill={theme.colors.accent}
					d="M132.83 385.11l1.38 1.78 2.25-.14-1.26 1.87.82 2.09-2.16-.62-1.74 1.43-.08-2.25-1.89-1.21 2.11-.77.57-2.18z"
					className="prefix__animable"
					style={{
						transformOrigin: '133.305px 388.315px',
					}}
				/>
				<path
					id="prefix__eljr4css7my1"
					fill={theme.colors.accent}
					d="M191.52 383.34l1.39 1.77 2.24-.13-1.26 1.86.82 2.1-2.16-.63-1.74 1.43-.07-2.25-1.9-1.21 2.12-.77.56-2.17z"
					className="prefix__animable"
					style={{
						transformOrigin: '191.995px 386.54px',
					}}
				/>
				<path
					id="prefix__elova7v926kjp"
					fill={theme.colors.accent}
					d="M176.12 400.48l.88 1.14 1.43-.09-.8 1.19.52 1.34-1.38-.4-1.11.91-.05-1.43-1.21-.78 1.35-.49.37-1.39z"
					className="prefix__animable"
					style={{
						transformOrigin: '176.415px 402.525px',
					}}
				/>
			</g>
			<g
				id="prefix__Planets--inject-2"
				className="prefix__animable"
				style={{
					transformOrigin: '228.349px 212.843px',
					animation: '1s 1 forwards cubic-bezier(.36,-.01,.5,1.38) slideLeft',
					animationDelay: '0s',
				}}
			>
				<circle
					id="prefix__elkcu2c9h4rk"
					cx={123.19}
					cy={304.91}
					r={19.94}
					fill={theme.colors.accent}
					className="prefix__animable"
					style={{
						transformOrigin: '123.19px 304.91px',
					}}
				/>
				<g
					id="prefix__elsi3m2a5yqgq"
					className="prefix__animable"
					opacity={0.3}
					style={{
						transformOrigin: '117.34px 311.14px',
					}}
				>
					<path
						id="prefix__el6pyfrlpv1ry"
						fill="#fff"
						d="M120.6 311.13a3.26 3.26 0 11-3.25-3.25 3.26 3.26 0 013.25 3.25z"
						className="prefix__animable"
						style={{
							transformOrigin: '117.34px 311.14px',
						}}
					/>
				</g>
				<g
					id="prefix__elyonwtn3i5wn"
					className="prefix__animable"
					opacity={0.3}
					style={{
						transformOrigin: '114.1px 301.76px',
					}}
				>
					<path
						id="prefix__el5bbxo72kmij"
						fill="#fff"
						d="M116 301.75a1.9 1.9 0 11-1.9-1.89 1.9 1.9 0 011.9 1.89z"
						className="prefix__animable"
						style={{
							transformOrigin: '114.1px 301.76px',
						}}
					/>
				</g>
				<path
					id="prefix__elgcgcagj606"
					fill={theme.colors.accent}
					d="M103.84 309.76c.09.35-17.07 16.81-12.64 20.32s23-5.49 38.89-18.3 30.35-28.37 26.38-32.49-23.79 8.07-23.79 8.07l2.37 1.52s12-7.69 15.78-4.71-7 14.64-22.27 25.78-30 18.94-32.27 15.89 8.5-13.22 8.5-13.22z"
					className="prefix__animable"
					style={{
						transformOrigin: '123.802px 304.642px',
					}}
				/>
				<g
					id="prefix__el0vko0rncrple"
					className="prefix__animable"
					opacity={0.3}
					style={{
						transformOrigin: '123.802px 304.642px',
					}}
				>
					<path
						id="prefix__eltd8mbo2xijr"
						d="M103.84 309.76c.09.35-17.07 16.81-12.64 20.32s23-5.49 38.89-18.3 30.35-28.37 26.38-32.49-23.79 8.07-23.79 8.07l2.37 1.52s12-7.69 15.78-4.71-7 14.64-22.27 25.78-30 18.94-32.27 15.89 8.5-13.22 8.5-13.22z"
						className="prefix__animable"
						style={{
							transformOrigin: '123.802px 304.642px',
						}}
					/>
				</g>
				<g
					id="prefix__elbhbdglx1pur"
					className="prefix__animable"
					opacity={0.3}
					style={{
						transformOrigin: '108.465px 304.643px',
					}}
				>
					<path
						id="prefix__ele00px1b99e4"
						fill="#fff"
						d="M112.2 290.86c.16.19-1.19 1.34-2.72 3.64a18.35 18.35 0 00-.4 20.17c1.44 2.37 2.74 3.57 2.57 3.75s-1.7-.78-3.48-3.14a17.26 17.26 0 01-2.42-4.53 17.36 17.36 0 01.25-12.45 17.11 17.11 0 012.6-4.44c1.87-2.28 3.51-3.14 3.6-3z"
						className="prefix__animable"
						style={{
							transformOrigin: '108.465px 304.643px',
						}}
					/>
				</g>
				<circle
					id="prefix__elw5o662rab7n"
					cx={342.97}
					cy={118.1}
					r={16.45}
					fill={theme.colors.accent}
					className="prefix__animable"
					style={{
						transformOrigin: '342.97px 118.1px',
					}}
					transform="rotate(-43.86)"
				/>
				<g
					id="prefix__elwbd8ls1egf"
					className="prefix__animable"
					opacity={0.4}
					style={{
						transformOrigin: '351.009px 111.861px',
					}}
				>
					<path
						id="prefix__elhzylfwp4mhr"
						d="M348.33 111.8a2.68 2.68 0 112.62 2.74 2.67 2.67 0 01-2.62-2.74z"
						className="prefix__animable"
						style={{
							transformOrigin: '351.009px 111.861px',
						}}
					/>
				</g>
				<g
					id="prefix__elot8kay8t91a"
					className="prefix__animable"
					opacity={0.4}
					style={{
						transformOrigin: '355.26px 118.09px',
					}}
				>
					<path
						id="prefix__eloujoombto8g"
						d="M353.69 118.06a1.57 1.57 0 111.53 1.6 1.56 1.56 0 01-1.53-1.6z"
						className="prefix__animable"
						style={{
							transformOrigin: '355.26px 118.09px',
						}}
					/>
				</g>
				<g
					id="prefix__elajlqc2pqs"
					className="prefix__animable"
					opacity={0.4}
					style={{
						transformOrigin: '351.585px 123.435px',
					}}
				>
					<path
						id="prefix__el0cmhuj3dduu"
						d="M351.6 122.86a.56.56 0 01.56.58.59.59 0 01-.59.57.58.58 0 01-.56-.59.57.57 0 01.59-.56z"
						className="prefix__animable"
						style={{
							transformOrigin: '351.585px 123.435px',
						}}
					/>
				</g>
			</g>
			<defs>
				<filter id="prefix__active" height="200%">
					<feMorphology in="SourceAlpha" operator="dilate" radius={2} result="DILATED" />
					<feFlood floodColor="#32DFEC" floodOpacity={1} result="PINK" />
					<feComposite in="PINK" in2="DILATED" operator="in" result="OUTLINE" />
					<feMerge>
						<feMergeNode in="OUTLINE" />
						<feMergeNode in="SourceGraphic" />
					</feMerge>
				</filter>
				<filter id="prefix__hover" height="200%">
					<feMorphology in="SourceAlpha" operator="dilate" radius={2} result="DILATED" />
					<feFlood floodColor="red" floodOpacity={0.5} result="PINK" />
					<feComposite in="PINK" in2="DILATED" operator="in" result="OUTLINE" />
					<feMerge>
						<feMergeNode in="OUTLINE" />
						<feMergeNode in="SourceGraphic" />
					</feMerge>
					<feColorMatrix values="0 0 0 0 0 0 1 0 0 0 0 0 0 0 0 0 0 0 1 0" />
				</filter>
			</defs>
		</svg>
	)
}

export default Creative
