import prism from './prism'

export default {
	prism,
	primary: `#013DC4`,
	secondary: `rgba(0,0,0,.6)`,
	grey: `rgba(0,0,0,.6)`,
	title: `#50586C`,
	background: `#E2D3F3`,
	backgroundModal: `rgba(209, 215, 235, 0.98)`,
	cleanBackground: `#FFFFFF`,
	accent: `#FFFFFF`,
	hover: `rgba(0, 0, 0, 0.07)`,
	gradient: `linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%)`,
	gradientImage: `linear-gradient(180deg, rgba(209, 232, 235, 0) 0%, rgba(209, 215, 235, 0.95) 71.88%, #d1d7eb 100%)`,
	anchorGradient: `linear-gradient(transparent 0%,transparent calc(50% - 9px), rgba(158, 189, 174, 0.35) calc(50% - 9px), rgba(158, 189, 174, 0.35) 100%)`,
	articleText: `#16161a`,
	textOffset: `rgb(12 61 196 / 41%)`,
	primaryOffset: `#ff3d6a`,
	track: `rgba(8, 8, 11, 0.3)`,
	progress: `#16161a`,
	card: `#fffdfa`,
	error: `#EE565B`,
	success: `#46B17B`,
	errorBackground: `rgba(238, 86, 91, 0.1)`,
	horizontalRule: `rgba(8, 8, 11, 0.15)`,
	inputBackground: `rgba(0, 0, 0, 0.05)`,
	textTitle: `#181820`,
	neumorphismShadown: `10px 10px 22px rgba(156, 171, 197, 0.28), -10px -10px 24px rgba(240, 243, 250, 0.65)`,
	border: `rgb(247, 222, 209)`,
	outline: `#cad4e5`,
	selection: `#8bd3dd`,
	modes: {
		dark: {
			grey: `#73737D`,
			primary: `#fff`,
			secondary: `#fff`,
			title: '#ffe8a3',
			accent: `#ffe8a3`,
			primaryOffset: `#ffdc71`,
			background: `#16161a`,
			backgroundModal: `rgba(17, 18, 22, 0.95)`,
			cleanBackground: `#16161a`,
			hover: `rgba(255, 255, 255, 0.07)`,
			gradient: `linear-gradient(180deg, #16161a 0%, rgb(35, 35, 47) 100%)`,
			anchorGradient: `linear-gradient(transparent 0%,transparent calc(50% - 9px),rgba(163, 219, 255, 0.44) calc(50% - 9px),rgba(163, 219, 255, 0.35) 100%)`,
			articleText: `#fff`,
			textOffset: `#818a91`,
			track: `rgba(255, 255, 255, 0.3)`,
			progress: `#fff`,
			card: `#20202a`,
			error: `#EE565B`,
			success: `#46B17B`,
			errorBackground: `rgba(238, 86, 91, 0.1)`,
			horizontalRule: `rgba(255, 255, 255, 0.15)`,
			inputBackground: `rgba(255, 255, 255, 0.07)`,
			textTitle: `#fff`,
			neumorphismShadown: `none`,
			border: `#2d2d2d`,
			outline: `#ffe7a2`,
			selection: `#43a9a3`,
		},
	},
}
