export default {
	token: `#ffd580`,
	languageJavascript: `#e8696b`,
	javascript: `#e8696b`,
	background: `#c9d6e1`,
	comment: `#5e6a76`,
	string: `#a8e2a8`,
	var: `#b3bac5`,
	number: `#e4854d`,
	constant: `#b3bac5`,
	plain: `#fff`,
	doctype: `#e8696b`,
	tag: `#ffe8a3`,
	keyword: `#d49fd4`,
	boolean: `#ff5874`,
	function: `#5F8DC3`,
	parameter: `#F9965D`,
	className: `#fcd285`,
	attrName: `#bf87ba`,
	attrValue: `#a8e2a8`,
	interpolation: `#fff`,
	punctuation: `#5FA8AA`,
	['maybe-class-name']: `#fff`,
	property: `#5ccfe6`,
	propertyAccess: `#fff`,
	namespace: `#b2ccd6`,
	highlight: `rgba(255,255,255,0.07)`,
	highlightBorder: `#e1bde2`,
	dom: `#5F8DC3`,
	operator: `#5FA8AA`,
}
